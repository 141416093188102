import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";

import Button from "../../buttons/Button";
import CustomScheduleSelect from "../../select/CustomScheduleSelect";
import ToggleSchedule from "../../toogle/ToggleSchedule";

import "./GameButtons.scss";
import 'swiper/css';
import CustomNumberSelect from "../../select/CustomNumberSelect";

export interface GameButtonsProps {
  scheduleButton?: [() => void, string];
  tableButton?: [() => void, string];
  scorerButton?: [() => void, string];
  nominationButton?: [() => void, string];
  cadreButton?: [() => void, string];
  showSelection? : boolean;
  activeSection: string;
}

function GameButtons({ scheduleButton, tableButton, scorerButton, nominationButton, cadreButton, showSelection = true, activeSection }: GameButtonsProps) {
  const { t } = useTranslation();
  const { toggleValue } = useSelector((state: RootState) => state.schedule);
  const getButtonClass = (buttonName: string) => {
    return `result-button ${activeSection === buttonName ? 'active' : ''}`;
  };

  return (
    <div className="game-button-container schedule-container">
      <div className="game-buttons">
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={15}
          loop={false}
          scrollbar={{ draggable: true }}
          centeredSlides={false}
        >
          {scheduleButton &&
            <SwiperSlide>
              <Button label={t('table.schedule')} customClass={getButtonClass('schedule')} onClick={scheduleButton[0]}/>
            </SwiperSlide>
          }

          {tableButton &&
          <SwiperSlide>
            <Button label={t('table.table')} customClass={getButtonClass('table')} onClick={tableButton[0]}/>
          </SwiperSlide>
          }

          {scorerButton &&
            <SwiperSlide >
              <Button label={t(`table.${toggleValue}.goalscorer`)} customClass={getButtonClass('scorer')} onClick={scorerButton[0]}/>
            </SwiperSlide>
          }

          {nominationButton &&
            <SwiperSlide >
              <Button label={t(`table.${toggleValue}.playerDay`)} customClass={getButtonClass('nomination')} onClick={nominationButton[0]}/>
            </SwiperSlide>
          }

          {cadreButton &&
            <SwiperSlide >
              <Button label={t('table.cadre')} customClass={getButtonClass('cadre')} onClick={cadreButton[0]}/>
            </SwiperSlide>
          }

        </Swiper>
      </div>
      {showSelection &&
        <div className="filter-container">
          <ToggleSchedule />
          <CustomNumberSelect customClass="game-buttons-select count-container"/>
          <CustomScheduleSelect customClass="game-buttons-select"/>
        </div>
      }
    </div>
  );
}

export default GameButtons;
