import React from "react";
import TeamItem from "./TeamItem";

interface MatchTeamsProps {
  homeLogo: string;
  homeTeam: string;
  guestLogo: string;
  guestTeam: string;
  homeGoal?: number;
  guestGoal?: number;
  status?: string;
}

function MatchTeams({ homeLogo, homeTeam, guestLogo, guestTeam, homeGoal = 0, guestGoal = 0, status = '' }: MatchTeamsProps) {

  return (
    <div className="match-teams-container">
      <TeamItem teamName={homeTeam} teamLogo={homeLogo} customClass="team-home" />
      <div className={`match-vs ${(status==='live' || status==='running') ? "live-score" : ""}`}>{homeGoal}:{guestGoal}</div>
      <TeamItem teamName={guestTeam} teamLogo={guestLogo} customClass="team-guest" />
    </div>
  );
}

export default MatchTeams;