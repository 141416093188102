import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";

import { setGameWeek } from '../../store/scheduleStore';

import { customStyles } from './style';
import './CustomSelect.scss';

interface CustomScheduleSelectProps {
  customClass?: string;
}

interface NumberOption {
  value: number;
  label: string;
  index: number;
}

function CustomNumberSelect({ customClass = '' }: CustomScheduleSelectProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { weekDayOptions, selectedWeek } = useSelector((state: RootState) => state.schedule);
  const [selectedOption, setSelectedOption] = useState<NumberOption | null>(null);

  useEffect(() => {
    if (weekDayOptions && weekDayOptions.length > 0) {
      setSelectedOption(weekDayOptions[selectedWeek]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekDayOptions]);

  useEffect(() => {
    if(selectedOption === null || selectedOption === undefined ) {
      return;
    }

    dispatch(setGameWeek(selectedOption.value));
  }, [selectedOption, dispatch]);

  const handleChange = (option: NumberOption | null) => {
    if (option) {
      setSelectedOption(option);
    }
  };

  return (
    <>
      {selectedOption ? (
        <div className={`custom-select-container ${customClass}`}>
          <span>{t('gameday')}</span>
          <Select
            options={weekDayOptions}
            styles={customStyles}
            value={selectedOption}
            onChange={handleChange}
            placeholder={t('filter')}
            isSearchable={false}
            aria-label={t('gameday-label')}
          />
        </div>
      ) : null}
    </>
  );
}

export default CustomNumberSelect;