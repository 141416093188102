import React from "react";

export interface ScorerTableItemProps {
  position: number;
  player: string;
  age: number;
  teamLogo: string;
  teamName: string;
  scorer?: number;
  nomination?: number;
  games: number;
  isScorer?: boolean;
  isNomination?: boolean;
}

function ScorerTableItem({ position, player, age, teamLogo, teamName, scorer, nomination, games, isScorer, isNomination }: ScorerTableItemProps) {
  // @ts-ignore
  return (
    <div className={`table-item-container`}>
      <div className="table-item_position">{position}</div>
      <div className="table-item_player">
        <span>{player}</span>
        {age ? <span>({age})</span> : ''}
      </div>
      <div className="table-item_team">
        {teamLogo ?
          <img src={teamLogo} alt={`logo ${teamName}`} width={32} height={32}/>
          :
          <img src="../../../assets/placeholder/placeholder-800.webp" alt={`logo ${teamName}`} width={32} height={32}/>
        }
        <span>{teamName}</span></div>
      {isScorer ? <div className="table-item_points">{scorer}</div> : null}
      {isNomination ? <div className="table-item_points">{nomination}</div> : null}
      <div className="table-item_games">{games}</div>
    </div>
  );
}

export default ScorerTableItem;