import React, { useEffect, useState } from "react";

interface TeamInformationProps {
  logo?: string;
  name?: string;
  address?: string;
  zip?: string;
  city?: string;
  email?: string;
  phone?: string;
  website?: string;
  description?: string;
}


function TeamInformation({ logo ='', name ='', address ='', zip ='', city ='', email ='', phone ='', website ='', description ='' }: TeamInformationProps) {
  const [image, setImage] = useState('');

  useEffect(() => {
    if (logo) {
      setImage(logo);
    } else {
      setImage(`${process.env.PUBLIC_URL}/assets/placeholder/placeholder-800.webp`);
    }
  }, [logo]);

  return (
    <div className="hero-team-info">

      <div className="team-hero-details">
        <img src={image} width={144} height={144} alt={`Team ${name}`}/>
        <div className="team-hero-address">
          <h1 className="h2">{name}</h1>
          {name && <span>{name}</span>}
          {address && <span>{address}</span>}
          {zip && city && <span>{zip} {city}</span>}
          {email && <a href={`mailto: ${email}`}>{email}</a>}
          {phone && <a href={`tel: ${phone}`}>{phone}</a>}
          {website &&
            <a href={website.startsWith('http://') || website.startsWith('https://') ? website : `http://${website}`}
              target="_blank" rel="noreferrer">
              {website}
            </a>}
        </div>
      </div>
      {description && <p dangerouslySetInnerHTML={{ __html: description }}/>}

    </div>
  );
}

export default TeamInformation;