import React from "react";
import { useTranslation } from "react-i18next";

interface CadreTableHeadProps {
  teamCadre?: boolean;
  customClass?: string;
}

function CadreTableHead({ teamCadre = false, customClass = "" }: CadreTableHeadProps) {
  const { t } = useTranslation();

  switch (teamCadre) {
  case true:
    return (
      <div className={`cadre-table-item-container cadre-table-head cadre-team ${customClass}`}>
        <div className="cadre-position">{t('live-panel.number')}</div>
        <div className="cadre-player">{t('live-panel.player-name')}</div>
        <div className="cadre-nation">{t('live-panel.nation')}</div>
        <div className="cadre-birthday">{t('live-panel.birthday')}</div>
        <div className="cadre-goals">{t('live-panel.position')}</div>
      </div>
    );
  default:
    return (
      <div className={`cadre-table-item-container cadre-table-head ${customClass}`}>
        <div className="cadre-position">{t('live-panel.number')}</div>
        <div className="cadre-player">{t('live-panel.player-name')}</div>
        <div className="cadre-nation">{t('live-panel.nation')}</div>
        <div className="cadre-goals">{t('live-panel.score')}</div>
        <div className="cadre-error">{t('live-panel.errors')}</div>
        <div className="cadre-ame">{t('live-panel.ame')}</div>
      </div>
    );
  }
}

export default CadreTableHead;