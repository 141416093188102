import { useCallback } from 'react';

interface OpenPopupProps {
  url: string;
  customHeight?: number;
  customWidth?: number;
}

function useOpenPopup({ url, customHeight = 800, customWidth = 600 }: OpenPopupProps) {
  const openPopup = useCallback(() => {
    const windowParams = `width=${customWidth},height=${customHeight},resizable=yes,scrollbars=yes,status=yes`;
    window.open(url, '_blank', windowParams);
  }, [customHeight, customWidth, url]);

  return openPopup;
}

export default useOpenPopup;