import { useEffect } from 'react';

const SeoComponent = () => {
  useEffect(() => {
    const fetchNewsJson = async () => {
      try {
        const response = await fetch('https://content.wasserball-bundesliga.de/storage/content/news/1.json');
        const newsData = await response.json();
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(newsData.data);
        document.head.appendChild(script);
      } catch (error) {
        console.error('Error fetching news JSON:', error);
      }
    };

    fetchNewsJson();
  }, []);

  return null;
};

export default SeoComponent;
