const OPEN_NAVI = 'navigation/OPEN_NAVI';

export const openNavi = (event: string) => ({ type: OPEN_NAVI, payload: event });

type Action = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

const location =  window.location.hostname;
let baseUrl = '';

switch (location) {
case 'localhost':
  baseUrl = 'http://localhost:3000/testApi';
  break;
case 'wasserball-bundesliga.de':
  baseUrl = 'https://content.wasserball-bundesliga.de';
  break;
case 'waba.codersunlimited.com':
  baseUrl = 'https://content-waba.codersunlimited.com';
  break;
default:
  baseUrl = 'https://content.wasserball-bundesliga.de';
}


export type InitialState = {
  isOpenNavigation: string;
  isLiveNavigation: Array<{
    _id: string;
    title: string;
    link: string;
    icon?: string;
    customClass?: string;
  }>;
  myTeamNavigation: Array<{
    _id: string;
    title: string;
    link: string;
    icon?: string;
    customClass?: string;
  }>;
  mainNavigation: Array<{
    _id: string;
    title: string;
    link: string;
    icon?: string;
    customClass?: string;
  }>;
  footerNavigation: Array<{
    _id: string;
    title: string;
    link: string;
    target?: string;
  }>;
  footerMainNavigation: Array<{
    _id: string;
    title: string;
    link: string;
    customClass?: string;
  }>;

};

const INITIAL_STATE = {
  isOpenNavigation: '',
  isLiveNavigation: [
    {
      _id: "mfy8Rl",
      title: "livestreams",
      link: `${baseUrl}/#livestream`,
      icon: "/assets/icons/play-black.svg",
      customClass: "live"
    }
  ],
  myTeamNavigation: [
    {
      _id: "ODHat5",
      title: "my-team",
      link: "https://editor.wasserball-bundesliga.de/team-editor",
      icon: "/assets/icons/studio-black.svg",
      customClass: "logout"
    }],
  mainNavigation: [
    {
      _id: "jRJov2",
      title: "games",
      link: "#games",
      icon: "/assets/icons/trophy-black.svg",
      customClass: "standard"
    },
    {
      _id: "tKhh9f",
      title: "news",
      link: "#newsroom",
      icon: "/assets/icons/fire-black.svg",
      customClass: "standard"
    },
    {
      _id: "d3woQ5",
      title: "teams",
      link: `/#team`,
      icon: "/assets/icons/icon-goal-black.svg",
      customClass: "standard"
    }
  ],
  footerMainNavigation: [
    {
      _id: "jcRJove2",
      title: "games",
      link: "#games",
      customClass: "standard"
    },
    {
      _id: "tKh1h39f",
      title: "news",
      link: "#newsroom",
      customClass: "standard"
    },
    {
      _id: "d3d2woQ5",
      title: "teams",
      link: "#team",
      customClass: "standard"
    }
  ],
  footerNavigation: [
    {
      _id: "4PovKic",
      title: "imprint",
      link: "/impressum",
      target: "_blank",
    },
    {
      _id: "7m3Jbi",
      title: "data-privacy",
      link: "/datenschutz",
      target: "_blank",
    },
    {
      _id: "hJKgwr",
      title: "team-editor",
      link: "https://editor.wasserball-bundesliga.de/team-editor",
      target: "_blank",
    }
  ],
};

function reducer(state: InitialState = INITIAL_STATE, action: Action) {
  switch (action.type) {
  case OPEN_NAVI:
    return { ...state, isOpenNavigation: action.payload };
  default:
    return state;
  }
}

export default reducer;