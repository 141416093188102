import React from "react";

import { CadreTableItemProps } from './Cadre.types';


function CadreTableItem({ teamCadre = false, jerseyNumber, player, gamePosition, nation, goals, birthday, errors, ame, customClass = "" }: CadreTableItemProps) {

  switch (teamCadre) {
  case true:
    return (
      <div className={`cadre-table-item-container cadre-team ${customClass}`}>
        <div className="cadre-position">{jerseyNumber}</div>
        <div className="cadre-player">{player}</div>
        <div className="cadre-nation">{nation}</div>
        <div className="cadre-birthday">{birthday}</div>
        <div className="cadre-game-position">{gamePosition}</div>
      </div>
    );
  default:
    return (
      <div className={`cadre-table-item-container ${customClass}`}>
        <div className="cadre-position">{jerseyNumber}</div>
        <div className="cadre-player">{player}</div>
        <div className="cadre-nation">{nation}</div>
        <div className="cadre-goals">{goals}</div>
        <div className="cadre-error">{errors}</div>
        <div className="cadre-ame">{ame}</div>
      </div>
    );
  }
}

export default CadreTableItem;