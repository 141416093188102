import React, { useState, useEffect } from 'react';

import TableItem from "./TableItem";

import "./Table.scss";
import TableHead from "./TableHead";

export interface TableDataItem {
  _id: string;
  index: number;
  guid: string;
  name: string;
  image: string;
  games: string;
  victories: number;
  draws: number;
  rank: number;
  defeats: number;
  goals: string;
  goalDifference: number;
  points: number;
  isActive: boolean;
}

function Table({ data }: { data: TableDataItem[] }) {
  const [tableData, setTableData] = useState<TableDataItem[]>([]);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    setTableData([...data].sort((a, b) => {
      if (a.rank === b.rank) {
        return a.rank - b.rank;
      }
      return a.rank - b.rank;
    }));
  }, [data]);

  return (
    <div className="table-container">
      <TableHead/>
      <>
        {tableData && tableData.map((item, index) => (
          <TableItem
            key={item._id}
            position={index + 1}
            teamLogo={item.image}
            teamName={item.name}
            games={item.games}
            wins={item.victories}
            draw={item.draws}
            loose={item.defeats}
            goals={item.goals}
            goalsDiff={item.goalDifference}
            points={item.points}
            isActive={item.isActive}/>
        ))}
      </>
    </div>
  );
}

export default Table;