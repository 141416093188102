import React from "react";

function LeagueLogos() {
  return (
    <>
      <div className='logos-container'>
        <img src={`${process.env.PUBLIC_URL}/assets/logos/logo-dieligadierockt@2x.webp`} alt="Wasserball Logo"
          width="238" height="94"/>
      </div>
    </>
  );
}

export default LeagueLogos;