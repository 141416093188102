import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import LivePanel from '../../components/livePanel/LivePanel';
import LivePanelHead from '../../components/panelCardsComponents/livePanelHead/LivePanelHead';
import LiveText from '../../components/liveText/LiveText';
import Anker from '../../components/Anker/Anker';
import Pagination from "../../components/pagination/Pagination";

import { GameData } from '../../components/livePanel/LivePanel';
import { isLiveGameSet } from "../../store/configStore";
import { initLivePagination, livePagination } from "../../store/paginationStore";

import './LiveLayout.scss';

interface LiveLayoutProps {
  anker?: string;
}

interface LiveGameProps {
  _id: string;
  title: string;
  data: GameData[];
}

function LiveLayout({ anker }: LiveLayoutProps) {
  const dispatch = useDispatch();
  const { baseUrl, liveTimeInterval, isLiveGame } = useSelector((state: RootState) => state.config);
  const { livePanelValue } = useSelector((state: RootState) => state.pagination);

  const [allLiveStreamData, setAllLiveStreamData] = useState<LiveGameProps[]>([]);
  const [liveStreamData, setLiveStreamData] = useState<LiveGameProps[]>([]);
  const [isLive, setLiveIsSet] = useState(false);

  const fetchLiveStreamData = useCallback(async () => {
    const urlAllLiveGames = `${baseUrl}/storage/live/allLiveGames.json`;

    try {
      const response = await fetch(urlAllLiveGames);
      const data = await response.json();
      setAllLiveStreamData(data.liveGames);
      setLiveIsSet(data.status);
    } catch (error) {
      console.error(`Failed to load liveGames data`, error);
    }
  }, [baseUrl]);

  useEffect(() => {
    fetchLiveStreamData().then(() => {});

    const intervalId = setInterval(async () => {
      try {
        await fetchLiveStreamData();
      } catch (error) {
        console.error('Error fetching live stream data:', error);
      }
    }, liveTimeInterval);

    return () => clearInterval(intervalId);
  }, [fetchLiveStreamData, liveTimeInterval]);

  useEffect(() => {
    if (allLiveStreamData === undefined || allLiveStreamData.length === 0) {
      return;
    }

    dispatch(initLivePagination(allLiveStreamData.length));
  }, [allLiveStreamData, dispatch]);

  useEffect(() => {
    if (allLiveStreamData === undefined || allLiveStreamData.length === 0) {
      return;
    }

    if (livePanelValue.now === 0) {
      setLiveStreamData(allLiveStreamData.slice(0, livePanelValue.count));
      return;
    }

    setLiveStreamData(allLiveStreamData.slice(livePanelValue.now * livePanelValue.count, (livePanelValue.now + 1) * livePanelValue.count));
  }, [allLiveStreamData, livePanelValue.count, livePanelValue.now]);

  useEffect(() => {

    if (!isLive) {
      dispatch(isLiveGameSet(false));
      return;
    }

    dispatch(isLiveGameSet(true));

  }, [isLive, dispatch]);

  return (
    <>
      {isLiveGame &&
      <>
        <Anker anker={anker}/>
        <section className="module_live-text">
          <div className="container">
            <LiveText/>
          </div>
        </section>

        {liveStreamData && liveStreamData.map((item: LiveGameProps) => (
          <section key={item._id} className="module_live-cards">
            <div className="container">
              <h3>{item.title}</h3>
              <LivePanelHead data={item.data}/>
              <LivePanel data={item.data}/>
            </div>
          </section>
        ))}

        <div className="container">
          {livePanelValue.maxData > 2 ?
            <Pagination dataAction={livePagination} state={livePanelValue} />
            :
            <></>
          }
        </div>
      </>
      }
    </>
  );
}

export default LiveLayout;