import React from "react";
import { useTranslation } from 'react-i18next';

import "../../livePanel/LivePanel.scss";

function DatePanelHead() {
  const { t } = useTranslation();

  return (
    <div className="live-panel-container live-layout date-panel-container all-schedule">
      <div className="live-panel-item">
        <div className="game-date-container">
          <div className="game-date">
            <span className="game-date_day">{t('live-panel.start')}</span>
            <span></span>
            <span className="game-date_time"></span>
          </div>
          <span className="game-date_location">{t('live-panel.location')}</span>
        </div>
        <div className="teamContainer">
          <div className="homeTeam">
            <span>{t('live-panel.home')}</span>
          </div>

          <div className="vs">
          </div>

          <div className="guestTeam">
            <span>{t('live-panel.guest')}</span>
          </div>
        </div>
        <div className={`scores-panel-container scores-head`}>
          <ul>
            <li>{t('live-panel.total')}</li>
            <li>{t('live-panel.first')}</li>
            <li>{t('live-panel.second')}</li>
            <li>{t('live-panel.third')}</li>
            <li>{t('live-panel.fourth')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DatePanelHead;