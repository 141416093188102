import React from "react";
import "./Button.scss";

interface ButtonProps {
  label: string;
  icon?: string;
  alt?: string;
  customClass?: string;
  onClick: () => void;
}

function Button({ label, icon, alt = "", customClass = "", onClick }: ButtonProps) {
  return (
    <button data-testid="button" className={`primary ${customClass}`} onClick={onClick} aria-label={`${label} Button`}>
      {icon && <img className="with-icon" src={icon} alt={alt} height="13px" width="13px" />}
      {label}
    </button>
  );
}

export default Button;