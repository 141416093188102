import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import { useTranslation } from 'react-i18next';

import TeamLayout from "../layouts/baseLayout/TeamLayout";
import News from "../components/newsRoom/News";
import TeamResultLayout from "../layouts/teamResultLayout/TeamResultLayout";

import { setTeamData, setScheduleData } from "../store/teamsStore";

function Team() {
  const dispatch = useDispatch();
  const [loadingSite, setLoadingSite] = useState(false);
  const location = useLocation();
  const teamId = location.pathname.split('/').pop();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { baseUrl, newsCount, liveStreamCount } = useSelector((state: RootState) => state.config);
  const { allTeamLinks } = useSelector((state: RootState) => state.fetchData);

  const [allNews, setAllNews] = useState<any>([]);
  const [allStreams, setAllStreams] = useState<any>([]);
  const [orderNumber, setOrderNumber] = useState<number | null>(null);
  const [isTeamBundesliga, setIsTeamBundesliga] = useState<boolean>(false);

  const fetchData = useCallback(async (order: number) => {
    const url = `${baseUrl}/storage/teams/${order}/team.json`;

    try {
      const response = await fetch(url, { method: 'GET' });
      const data = await response.json();
      dispatch(setTeamData(data));
      setAllNews(data.content);
      setAllStreams(data.livestreams);
      setLoadingSite(true);
    } catch (error) {
      console.log('Error fetching team data:', error);
    }
  }, [baseUrl, dispatch]);

  const fetchScheduleData = useCallback(async (order: number) => {
    const url = `${baseUrl}/storage/teams/${order}/schedule.json`;

    try {
      const response = await fetch(url, { method: 'GET' });
      const data = await response.json();
      dispatch(setScheduleData(data));
    } catch (error) {
      console.log('Error fetching team data:', error);
    }
  }, [baseUrl, dispatch]);

  useEffect(() => {
    if (allTeamLinks.length > 0) {
      const team = allTeamLinks.find((item: any) => item.pathLink === teamId);

      if (team) {
        (async () => {
          await fetchData(team.pathOrder);
          setOrderNumber(team.pathOrder);
          if (team.pathOrder === 3028) {
            setIsTeamBundesliga(true);
          }
        })();
      } else {
        navigate('/404');
      }
    }
  }, [allTeamLinks, fetchData, teamId, navigate]);

  useEffect(() => {
    if(orderNumber === null) {
      return;
    }

    (async () => {
      await fetchScheduleData(orderNumber);
    })();
  }, [orderNumber, fetchScheduleData]);

  useEffect(() => {

  }, []);


  return (
    <>
      {loadingSite &&
        <TeamLayout>
          {allStreams && (
            <>
              <News
                anker="livestream"
                title={t("title.last-live-streams")}
                allNews={allStreams}
                newsCount={liveStreamCount}
                customClass="news-team last-live-streams"
                isSelectActive={false}
              />
            </>
          )}
          
          {!isTeamBundesliga && orderNumber && <TeamResultLayout teamsPage={true} url={baseUrl} orderNumber={orderNumber}/>}

          {allNews && (
            <News
              anker="newsroom"
              title={t("title.our-content")}
              allNews={allNews}
              newsCount={newsCount}
              customClass="news-team"
              isSelectActive={false}
            />
          )}
        </TeamLayout>
      }
    </>
  );
}

export default Team;