import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useTranslation } from "react-i18next";

import BaseLayout from "../layouts/baseLayout/BaseLayout";
import LiveLayout from "../layouts/liveLayout/LiveLayout";
import GamesResultLayout from "../layouts/gamesResultLayout/GamesResultLayout";
import AllNews from "../components/newsRoom/AllNews";
import TeamCards from "../components/teamCards/TeamCards";
import { addNews, nextNewsData } from "../store/newsroomStore";

function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLiveStream, newsCount } = useSelector((state: RootState) => state.config);
  const { allNewsData } = useSelector((state: RootState) => state.fetchData);
  const { newsData } = useSelector((state: RootState) => state.newsroom);

  useEffect(() => {
    if (allNewsData === undefined || allNewsData.length === 0) {
      return;
    }

    dispatch(addNews(allNewsData.data));
    dispatch(nextNewsData(allNewsData.next));

  }, [allNewsData, dispatch]);

  return (
    <BaseLayout leagueLogoActive={true}>
      {isLiveStream && <LiveLayout anker="livestream" />}
      <GamesResultLayout anker="games" />
      {newsData && (
        <AllNews
          title={t("title.news")}
          allNews={newsData}
          newsCount={newsCount}
          anker="newsroom"
        />
      )}
      <TeamCards anker="team" />
    </BaseLayout>
  );
}

export default Home;