import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import useWindowSize from "../../hooks/useWindowSize";

import NewsMasonry from "./NewsMasonry";
import CustomSelect from "../select/CustomSelect";
import Anker from "../Anker/Anker";

import { changeFilter, resetNews, loadNewsStateIndex } from "../../store/newsroomStore";

import "./News.scss";

interface NewsProps {
  title?: string;
  allNews?: any;
  newsCount?: number;
  customClass?: string;
  isSelectActive?: boolean;
  anker?: string;
}

interface NewsItem {
  id: number;
  type: string;
  class?: string;
  date: number;
  title: string;
  intro: string;
  description: string;
  image: string;
  link: string;
  newsTeam: boolean;
}

function News({ title, allNews, newsCount, customClass, isSelectActive = true, anker }: NewsProps) {
  const dispatch = useDispatch();
  const [resetOption, setResetOption] = useState('');
  const [allNewsData, setAllNewsData] = useState<any>([]);
  const [newsData, setNewsData] = useState<NewsItem[] | null>(null);
  const [totalNewsCount, setTotalNewsCount] = useState<number>(0);
  const { sortedOptions } = useSelector((state: RootState) => state.newsroom);

  const [selectIndexState, setSelectIndexState] = useState<number>(10);
  const [isSelectedState, setIsSelectedState] = useState<boolean>(false);
  const { width } = useWindowSize();

  useEffect(() => {
    const savedState = loadNewsStateIndex();
    if (savedState !== null) {
      setSelectIndexState(savedState.index);
      setIsSelectedState(true);
    } else {
      setIsSelectedState(true);
    }
  }, []);

  useEffect(() => {
    if (allNews === undefined || allNews.length === 0) {
      return;
    }

    setAllNewsData(allNews);

  }, [allNews]);

  useEffect(() => {

    if (allNewsData === undefined || allNewsData.length === 0) {
      return;
    }

    const sortedNewsData = [...allNewsData].sort((a, b) => b.date - a.date);

    setNewsData(sortedNewsData.slice(0, newsCount));
    setTotalNewsCount(sortedNewsData.length);
  }, [allNewsData, newsCount]);

  function loadMore() {
    let count = 4;

    if (width <= 568) {
      count = 1;
    } else if (width <= 900) {
      count = 2;
    } else if (width <= 1280) {
      count = 3;
    } else {
      count = 4;
    }

    const nextIndex = newsData ? newsData.length : 0;

    const moreNews = allNews.slice(0, nextIndex + count);
    const sortedNews = moreNews.sort((a: NewsItem, b: NewsItem) => b.date - a.date);

    setNewsData(sortedNews);
  }

  function resetFilter() {
    setResetOption('empty');
    setTimeout(() => setResetOption(''), 0);

    dispatch(resetNews());
  }

  return (
    <>
      {newsData && (
        <>
          <Anker anker={anker}/>
          <section className={`module_newsroom ${customClass}`}>
            <div className="container">
              <div className="newsroom-select">
                {title && <h2>{title}</h2>}
                {isSelectActive && isSelectedState &&
                <CustomSelect
                  options={sortedOptions}
                  stateAction={changeFilter} 
                  resetClick={() => resetFilter()}
                  resetCustomSelect={resetOption}
                  startSelected={selectIndexState}
                />
                }
              </div>

              <NewsMasonry newsData={newsData} totalNewsCount={totalNewsCount} onClick={() => loadMore()}/>

            </div>
          </section>
        </>
      )}
    </>
  );
}

export default News;