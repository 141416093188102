import { CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select';

const customStyles: StylesConfig<any, boolean, GroupBase<any>> = {
  container: (base: CSSObjectWithLabel) => ({
    ...base,
    width: "100%",
    padding: 0,
    borderWidth: 0,
    height: 34,
  }),
  control: (base: CSSObjectWithLabel, state: { isFocused: boolean }) => ({
    ...base,
    borderRadius: 20,
    backgroundColor: state.isFocused ? '#c0c0c0' : '#F9FBFC',
    color: state.isFocused ? '#F9FBFC' : '#1D496F',
    borderWidth: state.isFocused ? 3 : 3,
    borderColor: state.isFocused ? '#1D496F' : '#F9FBFC',
    padding: 0,
    paddingLeft: 16,
    paddingRight: 10,
    cursor: 'pointer',
  }),
  option: (base: CSSObjectWithLabel, state: { isSelected: boolean, isFocused: boolean }) => ({
    ...base,
    backgroundColor: state.isSelected ? '#003D85' : state.isFocused ? '#D3E2F4' : 'white',
    color: state.isSelected ? 'white' : 'black',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#003D85',
      color: 'white',
    },
  }),
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    color: '#1D496F',
    fontWeight: '700',
    fontStyle: 'italic',
    fontFamily: 'Roboto Condensed, sans-serif',
  }),
  placeholder: (base: CSSObjectWithLabel, state: { isFocused: boolean }) => ({
    ...base,
    fontWeight: '700',
    fontStyle: 'italic',
    fontFamily: 'Roboto Condensed, sans-serif',
    color: state.isFocused ? '#1D496F' :'#5C809E',
  }),
  dropdownIndicator: (base: CSSObjectWithLabel, state: { hasValue: boolean }) => ({
    ...base,
    borderWidth: 0,
    color: state.hasValue ? '#002345' : '#5C809E',
    ':hover': {
      color: state.hasValue ? '#002345' : '#5C809E',
    },
    ':focus-visible': {
      color: state.hasValue ? '#002345' : '#5C809E',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export { customStyles };