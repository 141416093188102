import React from "react";

interface ScoreItemProps {
  homeScore: string;
  guestScore: string;
  customClass?: string;
}

function ScoreItem({ homeScore, guestScore, customClass }: ScoreItemProps) {
  return (
    <li className={`${customClass}`}>
      {homeScore === "keine Daten" ? <span></span> : <span>{homeScore}</span>}
      <span className="colon">:</span>
      <span className="total">total</span>
      <span>{guestScore}</span>
    </li>
  );
}

export default ScoreItem;