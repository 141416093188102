import React from "react";
import { useTranslation } from "react-i18next";

import BaseLayout from "../layouts/baseLayout/BaseLayout";

function NotFoundSite() {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <section className="module_not-found">
        <div className="container">
          <div className="not-found-container">
            <div className="not-found-content">
              <h1>{t('404.title')}</h1>
              <a className="button" href="/">{t('404.button')}</a>
            </div>
            <div className="not-found">{t('404.404')}</div>
          </div>
        </div>
      </section>
    </BaseLayout>
  );
}

export default NotFoundSite;