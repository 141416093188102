import React, { useState, useCallback, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

import LiveTickerLayout from "../layouts/baseLayout/LiveTickerLayout";
import PopUp from "../components/PopUp/PopUp";

declare const Cookiebot: any;

function LiveStream() {
  const { streamId } = useParams<{ streamId: string }>();
  const { baseUrl } = useSelector((state: RootState) => state.config);
  const { t } = useTranslation();

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [liveStreamData, setLiveStreamData] = useState<any>([]);

  const fetchData = useCallback(async (order: number) => {
    const url = `${baseUrl}/storage/live/${order}/stream.json`;

    try {
      const response = await fetch(url, { method: 'GET' });
      const data = await response.json();
      setLiveStreamData(data);
      setIsDataLoading(true);
    } catch (error) {
      console.log('Error fetching team data:', error);
    }
  }, [baseUrl]);

  useEffect(() => {
    if(isDataLoading) {
      return;
    }

    if (streamId) {
      fetchData(Number(streamId)).then(() => {});
    }

  }, [isDataLoading, fetchData, streamId]);

  return (
    <LiveTickerLayout customHeroClass="popup-hero" isNavigation={false} showUpToButton={false} >
      <PopUp>
        <div className="logos-container">
          <img src={`${process.env.PUBLIC_URL}/assets/logos/signet-wasserball-maenner@2x.webp`}
            alt='männer wasserball liga logo' height="40px" width="40px"/>
          <img src={`${process.env.PUBLIC_URL}/assets/logos/Wasserball_Bundesliga_schriftzug@2x.webp`}
            alt="schriftzug wasserball liga" height="40px" width="150px"/>
        </div>
        <div className="popup-live-ticker">
          <h1 className="h2">{t('live-stream')}</h1>
        </div>

        <div className="popup-live-stream">
          {isDataLoading && liveStreamData.length !== 0 && liveStreamData.streamUrl ?
            <>
              <div className="cookieconsent-optin-marketing">
                <iframe width="100%" height="100%" src={liveStreamData.streamUrl}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
              </div>
              <div className="cookieconsent-optout-marketing">
                <button onClick={() => Cookiebot.renew()}>{t('cookies.video')}</button>
              </div>
            </>
            :
            <h2>{t('live-panel.no-video')}</h2>
          }
        </div>
      </PopUp>
    </LiveTickerLayout>
  );
}

export default LiveStream;
