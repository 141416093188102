import { ThunkAction } from 'redux-thunk';
import { RootState } from './store';
import { Action as ReduxAction } from 'redux';

const LOAD_ALL_DATA = 'scheduleData/LOAD_ALL_DATA';

const CHANGE_TOGGLE = 'schedule/CHANGE_TOGGLE';
const SET_SELECT_OPTIONS = 'schedule/SET_SELECT_OPTIONS';
const CHANGE_SELECT_OPTION = 'schedule/CHANGE_SELECT_OPTION';
const SET_GAME_SCHEDULE_DATA = 'schedule/SET_GAME_SCHEDULE_DATA';
const SET_GAME_SCHADULES = 'schedule/SET_GAME_SCHADULES';
const SET_GAME_WEEK = 'schedule/SET_GAME_WEEK';

const SET_TEST_TABLE = 'schedule/SET_TEST_TABLE';

const location =  window.location.hostname;
let baseUrl = '';

switch (location) {
case 'localhost':
  baseUrl = 'http://localhost:3000/testApi';
  break;
case 'wasserball-bundesliga.de':
  baseUrl = 'https://content.wasserball-bundesliga.de';
  break;
case 'waba.codersunlimited.com':
  baseUrl = 'https://content-waba.codersunlimited.com';
  break;
default:
  baseUrl = 'https://content.wasserball-bundesliga.de';
}

const urlSelected = `${baseUrl}/storage/content/scheduleOptions.json?t=` + new Date().getTime();
const urlAllTable = `${baseUrl}/storage/leagues/league.json?t=` + new Date().getTime();
const urlAllBestList = `${baseUrl}/storage/content/allBestList.json?t=` + new Date().getTime();
const urlWeekDay = `${baseUrl}/storage/content/gameDays.json?t=` + new Date().getTime();
const urlAllSchedule = `${baseUrl}/storage/content/allSchedules.json?t=` + new Date().getTime();

interface LoadDataAction extends ReduxAction<typeof LOAD_ALL_DATA> {
  payload: any;
  dataType: 'selected' | 'weekDay' | 'table' | 'schedule' | 'allbest' ;
}

const loadScheduleData = (url: string, dataType: 'selected' | 'weekDay' | 'table' | 'schedule' | 'allbest' ): ThunkAction<void, RootState, unknown, LoadDataAction> => async dispatch => {

  try {
    const response = await fetch(url, {
      method: 'GET'
    });
    const data = await response.json();
    dispatch({ type: LOAD_ALL_DATA, payload: data, dataType });
  } catch (error) {
    console.error(`Failed to load ${dataType} data`, error);
  }
};

export const loadScheduleOptions = () => loadScheduleData(urlSelected, 'selected');
export const loadWeekDayData = () => loadScheduleData(urlWeekDay, 'weekDay');
export const loadTableData = () => loadScheduleData(urlAllTable, 'table');
export const loadAllScheduleData = () => loadScheduleData(urlAllSchedule, 'schedule');
export const loadAllBestData = () => loadScheduleData(urlAllBestList, 'allbest');

export const changeToggle = (event: { toggle: boolean; }) => ({ type: CHANGE_TOGGLE, payload: event });
export const changeSelectOption = (event: { index: number; value: any }) => ({ type: CHANGE_SELECT_OPTION, payload: event });
export const setGameWeek = (week: number) => ({ type: SET_GAME_WEEK, payload: week });

type Action = {
  dataType: string;
  type: string;
  payload?: any;
};

export type InitialState = {
  toggleValue: string;
  toggleCheck: boolean;
  loadedSelected: boolean;
  allSelectedOption: any;
  selectOptions: any;
  selectedIndex: number;
  selectedValue: string;
  allWeekDayOptions: any;
  weekDayOptions: any;
  selectedCategory: number | null;
  selectedWeek: number;
  allSchedule: any;
  schedule: any;
  allTable: any;
  table: any;
  allBestList: any;
  scorer: any;
  mvp: any;
  testTable: any;
}

const INITIAL_STATE: InitialState = {
  toggleValue: 'man',
  toggleCheck: false,
  loadedSelected: false,
  allSelectedOption: [],
  selectOptions: [],
  selectedIndex:  0,
  selectedValue: "1",
  allWeekDayOptions: [],
  weekDayOptions: [],
  selectedCategory: null,
  selectedWeek: 0,
  allSchedule: [],
  schedule: [],
  allTable: [],
  table: [],
  allBestList: [],
  scorer: [],
  mvp: [],
  testTable: []
};

function reducer(state: InitialState = INITIAL_STATE, action: Action) {
  switch (action.type) {
  case LOAD_ALL_DATA:
    let newState = { ...state };

    if (action.dataType === 'selected') {
      newState = {
        ...newState,
        allSelectedOption: action.payload,
        selectOptions: action.payload?.[state.toggleValue]?.sort((a: any, b: any) => a.label - b.label),
      };
    }

    if (action.dataType === 'table') {
      newState = {
        ...newState,
        allTable: action.payload,
        table: filteredScorerData(action.payload, state.toggleValue, state.selectedValue, action.dataType)
      };
    } else if (action.dataType === 'allbest') {
      newState = {
        ...newState,
        allBestList: action.payload,
        scorer: filteredScorerData(action.payload, state.toggleValue, state.selectedValue, 'scorer'),
        mvp: filteredScorerData(action.payload, state.toggleValue, state.selectedValue, 'mvp')
      };
    } else if (action.dataType === 'schedule') {
      newState = {
        ...newState,
        allSchedule: action.payload,
        weekDayOptions: gameDayOptions(action.payload, state.toggleValue, state.selectedValue),
        selectedWeek: gameDayValue(action.payload, state.toggleValue, state.selectedValue),
        schedule: filteredScorerData(action.payload, state.toggleValue, state.selectedValue, action.dataType),
      };
    }

    return newState;
  case CHANGE_TOGGLE:
    // eslint-disable-next-line no-case-declarations
    const { toggle } = action.payload;
    return { ...state,
      toggleCheck: toggle, toggleValue: toggleAction(toggle),
      selectOptions: state.allSelectedOption[toggleAction(toggle)],
      selectedValue: state.allSelectedOption[toggleAction(toggle)][0].value,
      selectedIndex: 0,
      weekDayOptions: gameDayOptions(state.allSchedule, toggleAction(toggle),  state.allSelectedOption[toggleAction(toggle)][0].value),
      selectedWeek: gameDayValue(state.allSchedule, toggleAction(toggle),  state.allSelectedOption[toggleAction(toggle)][0].value),
      table: filteredScorerData(state.allTable, toggleAction(toggle), state.allSelectedOption[toggleAction(toggle)][0].value, 'table'),
      scorer: filteredScorerData(state.allBestList, toggleAction(toggle), state.allSelectedOption[toggleAction(toggle)][0].value, 'scorer'),
      mvp: filteredScorerData(state.allBestList, toggleAction(toggle), state.allSelectedOption[toggleAction(toggle)][0].value, 'mvp'),
      schedule: filteredScorerData(state.allSchedule, toggleAction(toggle), state.allSelectedOption[toggleAction(toggle)][0].value, 'schedule'),
    };
  case SET_SELECT_OPTIONS:
    return { ...state,
      selectOptions: action.payload,
    };
  case CHANGE_SELECT_OPTION:
    return { ...state,
      selectedValue:action.payload.value,
      selectedIndex: action.payload.index,
      weekDayOptions: gameDayOptions(state.allSchedule, state.toggleValue, action.payload.value),
      selectedWeek: gameDayValue(state.allSchedule, state.toggleValue, action.payload.value),
      scorer: filteredScorerData(state.allBestList, state.toggleValue, action.payload.value, 'scorer'),
      mvp: filteredScorerData(state.allBestList, state.toggleValue, action.payload.value, 'mvp'),
      table: filteredScorerData(state.allTable, state.toggleValue, action.payload.value, 'table'),
      schedule: filteredScorerData(state.allSchedule, state.toggleValue, action.payload.value, 'schedule')
    };
  case SET_GAME_SCHEDULE_DATA:
    return { ...state,
      schedule: action.payload.schedule,
    };
  case SET_GAME_SCHADULES:
    return { ...state, table: action.payload };
  case SET_GAME_WEEK:
    return { ...state, selectedWeek: action.payload };
  case SET_TEST_TABLE:
    return { ...state, testTable: action.payload };
  default:
    return state;
  }
}

function toggleAction(state: boolean) {
  return state ? 'woman' : 'man';
}

function gameDayOptions(data: any, type: string, league: string) {

  if (Object.keys(data).length === 0 || data.length === 0 || league === undefined) {
    return [];
  }
  const selectOptions: { _id: string; value: any; label: any; index: number; }[] = [];
  const cacheData = data?.[type]?.[league].data;
    
  Object.entries(cacheData).forEach((item: any, index) => {
    selectOptions.push({ _id: generateRandomId(),  value: item[0], label: item[0], index: index });
  });
  
  return selectOptions;
}

function gameDayValue(data: any, type: string, league: string) {
  if (Object.keys(data).length === 0 || data.length === 0 || league === undefined) {
    return 0;
  }

  const cacheData = data?.[type]?.[league]?.upComingMatchDay ? data?.[type]?.[league]?.upComingMatchDay : 1;
  return cacheData - 1;
}

function generateRandomId() {
  return Math.random().toString(36).substring(2, 22);
}

function filteredScorerData(fetchData: any, value: string, selectedValue: string, filterValue: string) {
  let cacheData = [];

  if (value === '' || selectedValue === '' || fetchData.length === 0) {
    return [];
  }

  if(filterValue === 'table') {
    const tableData = fetchData?.[value];

    if (tableData === undefined) {
      return [];
    }

    const filteredData = tableData.filter((item: { _id: string }) => item._id === selectedValue);
    cacheData = filteredData[0]?.data;
  } else {
    cacheData = fetchData?.[value]?.[selectedValue]?.data;
  }


  if (cacheData === undefined) {
    return [];
  }

  switch (filterValue) {
  case 'table':
    return cacheData;
  case 'scorer':
    if (cacheData?.[filterValue] === undefined) {
      return [];
    }
    return cacheData?.[filterValue];
  case 'mvp':
    if (cacheData?.[filterValue] === undefined) {
      return [];
    }
    return cacheData?.[filterValue];
  case 'schedule':
    // eslint-disable-next-line no-case-declarations
    return cacheData;
  }
}

export default reducer;
