import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

import { useTranslation } from "react-i18next";

interface ScorerTableHeadProps {
  isScorer?: boolean;
  isNomination?: boolean;
}

function ScorerTableHead({ isScorer, isNomination }: ScorerTableHeadProps) {
  const { t } = useTranslation();
  const { toggleValue } = useSelector((state: RootState) => state.schedule);

  return (
    <div className={`table-item-container table-head`}>
      <div className="table-item_position">{t('table.position')}</div>
      <div className="table-item_player">{t(`table.${toggleValue}.player`)}</div>
      <div className="table-item_team">{t('table.team')}</div>
      {isScorer ? <div className="table-item_points">{t('table.goal')}</div> : null}
      {isNomination ? <div className="table-item_points">{t('table.nomination')}</div> : null}
      <div className="table-item_games">{t('table.games')}</div>
    </div>
  );
}

export default ScorerTableHead;
