import React from "react";

interface LiveTickerGameProps {
  children: React.ReactNode;
  customClass?: string;
}

function LiveTickerGame({ children, customClass }: LiveTickerGameProps) {

  return (
    <div className={`live-ticker-game-container ${customClass}`} >
      {children}
    </div>
  );
}

export default LiveTickerGame;