import React, { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import ScorerTableItem from './ScorerTableItem';
import ScorerTableHead from "./ScorerTableHead";

export interface TableDataItem {
  _id: string;
  id: number;
  index: number;
  guid: string;
  player: string;
  personAge: number;
  name: string;
  picture: string;
  games: number;
  scorer?: number;
  nomination?: number;
  isScorer?: boolean;
  isNomination?: boolean;
}

interface TableBooleanData {
  data: TableDataItem[];
  isScorer?: boolean;
  isNomination?: boolean;
}

function ScorerTable({ isScorer, isNomination, data = [] }: TableBooleanData) {
  const [tableData, setTableData] = useState<TableDataItem[]>([]);

  const sortData = useCallback((data: TableDataItem[]) => {
    if(isScorer) {
      setTableData([...data]);
    } else if(isNomination) {
      setTableData([...data]);
    }
  }, [isScorer, isNomination]);

  useEffect(() => {

    if (data.length === 0 ) {
      return;
    }

    sortData(data);
  }, [data, sortData]);


  return (
    <div className="table-container scorer-table">
      <ScorerTableHead isScorer={isScorer} isNomination={isNomination} />
      {tableData && tableData.map((item) => (
        <ScorerTableItem
          key={uuidv4()}
          position={item.id + 1}
          player={item.player}
          age={item.personAge}
          teamLogo={item.picture}
          teamName={item.name}
          scorer={item.scorer}
          nomination={item.nomination}
          games={item.games}
          isScorer={isScorer}
          isNomination={isNomination}
        />
      ))}
    </div>
  );
}

export default ScorerTable;