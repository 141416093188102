import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import Button from "../../buttons/Button";
import useOpenPopup from "../../../hooks/useOpenPopup";

import "./LiveButtons.scss";

interface LiveButtonsProps {
  customClass?: string;
  tickerLinker?: string;
  streamLink?: string;
}

function LiveButtons({ customClass = "", tickerLinker = "", streamLink = "" }: LiveButtonsProps) {

  const { popupWidth, popupHeight } = useSelector((state: RootState) => state.config);
  const linkRegex = /\/stream\/[0-9]+/i;

  const openLiveTicker = useOpenPopup({ url: tickerLinker, customHeight: popupHeight, customWidth: popupWidth } );
  const openLiveStream = useOpenPopup({ url: streamLink, customHeight: popupHeight, customWidth: popupWidth } );

  const checkLink = () : boolean =>  {
    const validLink = linkRegex.test(streamLink);
    return validLink;
  };

  return (
    <div className={`live-buttons-container ${customClass}`}>
      {
        (streamLink && checkLink()) ?
        // eslint-disable-next-line react/react-in-jsx-scope
          <Button
            label="Stream"
            icon="../assets/icons/play-black.svg"
            alt="Live Stream"
            onClick={openLiveStream}
            customClass="icon-button"
          />
          :
          <span className="placeholder-stream-link"></span>
      }
      {tickerLinker &&
        // eslint-disable-next-line react/react-in-jsx-scope
        <Button
          label="Ticker"
          icon="../assets/icons/live-black.svg"
          alt="Live Ticker"
          onClick={openLiveTicker}
          customClass="icon-button"
        />
      }
    </div>
  );
}

export default LiveButtons;