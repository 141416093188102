import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { useDispatch } from "react-redux";

import { customStyles } from './style';
import './CustomSelect.scss';

interface Option {
  value: string;
  label: string;
}

interface CustomSelectProps {
  options?: Option[];
  stateAction?: any;
  customClass?: string;
  resetClick?: () => void;
  resetCustomSelect?: string;
  notSelected?: boolean;
  savedState?: boolean;
  startSelected?: number;
}

function CustomSelect({ options = [], stateAction, resetClick, resetCustomSelect, customClass = '', notSelected = true, savedState = false, startSelected = 1 }: CustomSelectProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<Option | undefined | null>(notSelected ? options[startSelected] : null);

  useEffect(() => {
    if (stateAction) {
      if (selectedOption) {
        dispatch(stateAction(selectedOption));
      }
    }
  }, [dispatch, stateAction, selectedOption, savedState]);

  useEffect(() => {
    switch (resetCustomSelect) {
    case 'option':
      setSelectedOption(options[0]);
      break;
    case 'empty':
      setSelectedOption(null);
      break;
    default:
      break;
    }

  }, [resetCustomSelect, options]);

  return (
    <div className={`custom-select-container ${customClass}`}>
      <Select
        options={options}
        styles={customStyles}
        value={selectedOption || null}
        onChange={setSelectedOption}
        defaultValue={[options[startSelected]]}
        placeholder={t('filter')}
        isSearchable={false}
        aria-label={t('filter')}
      />

      <span className="reset-select" onClick={resetClick}>{t('reset-filter')}</span>
    </div>
  );
}

export default CustomSelect;