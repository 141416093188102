import React from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { openNavi } from "../../store/navigationStore";

interface NavItemProps {
  icon?: string;
  value: string;
  customClass?: string;
  link: string;
}

function NavItem({ icon, value, customClass, link }: NavItemProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function closeMenu() {
    dispatch(openNavi(''));
  }

  return (
    <li>
      <a className={`item ${customClass}`} href={link} onClick={() => closeMenu()}>
        {icon ? <img src={`${process.env.PUBLIC_URL}${icon}`} alt="menu logo" width="24" height="24"/> : <span></span>}
        {t(`mainNavigation.${value}`)}
      </a>
    </li>
  );
}

export default NavItem;