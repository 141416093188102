import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import NewsMasonry from "./NewsMasonry";
import CustomSelect from "../select/CustomSelect";
import Anker from "../Anker/Anker";

import { changeFilter, resetNews, loadNewsStateIndex } from "../../store/newsroomStore";

import "./News.scss";
import useWindowSize from "../../hooks/useWindowSize";

interface NewsProps {
  title?: string;
  allNews?: any;
  newsCount?: number;
  customClass?: string;
  isSelectActive?: boolean;
  anker?: string;
}

function AllNews({ title, customClass, isSelectActive = true, anker }: NewsProps) {
  const dispatch = useDispatch();

  const [resetOption, setResetOption] = useState('');
  const { sortedOptions, newsData } = useSelector((state: RootState) => state.newsroom);
  const { newsCount } = useSelector((state: RootState) => state.config);

  const [selectIndexState, setSelectIndexState] = useState<number>(10);
  const [isSelectedState, setIsSelectedState] = useState<boolean>(false);

  const [isMoreLoading, setIsMoreLoading] = useState<boolean>(false);
  const [nowNewsCount, setNowNewsCount] = useState<number>(0);
  const [totalNewsCount, setTotalNewsCount] = useState<number>(0);
  const [sortedNewsData, setSortedNewsData] = useState<any>([]);

  const { width } = useWindowSize();

  useEffect(() => {
    const savedState = loadNewsStateIndex();
    if (savedState !== null) {
      setSelectIndexState(savedState.index);
      setIsSelectedState(true);
    } else {
      setIsSelectedState(true);
    }
  }, []);


  useEffect(() => {

    if (newsData === undefined || newsData.length === 0) {
      setSortedNewsData([]);
      setIsMoreLoading(false);
      return;
    }

    const sortedCacheNewsData = [...newsData].sort((a, b) => b.date - a.date);

    setSortedNewsData(sortedCacheNewsData.slice(0, newsCount));
    setTotalNewsCount(sortedCacheNewsData.length);

    if (sortedCacheNewsData.length > sortedNewsData.length) {
      setNowNewsCount(sortedNewsData.length);
      setIsMoreLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsData]);

  useEffect(() => {
    if (nowNewsCount >= totalNewsCount) {
      setIsMoreLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowNewsCount]);

  function loadMore() {
    let count = 4;

    if (width <= 568) {
      count = 1;
    } else if (width <= 900) {
      count = 2;
    } else if (width <= 1280) {
      count = 3;
    } else {
      count = 4;
    }

    const nextIndex = sortedNewsData ? sortedNewsData.length : 0;

    const moreNews = newsData.slice(0, nextIndex + count);
    const sortedNews = moreNews.sort((a, b) => b.date - a.date);
    setNowNewsCount(sortedNews.length);
    setSortedNewsData(sortedNews);
  }

  function resetFilter() {
    setResetOption('empty');
    setTimeout(() => setResetOption(''), 0);

    dispatch(resetNews());
  }

  return (
    <>
      {newsData && (
        <>
          <Anker anker={anker}/>
          <section className={`module_newsroom ${customClass}`}>
            <div className="container">
              <div className="newsroom-select">
                {title && <h2>{title}</h2>}
                {isSelectActive && isSelectedState &&
                  <CustomSelect
                    options={sortedOptions}
                    stateAction={changeFilter}
                    resetClick={() => resetFilter()}
                    resetCustomSelect={resetOption}
                    startSelected={selectIndexState}
                  />
                }
              </div>

              <NewsMasonry newsData={sortedNewsData} isMoreLoading={isMoreLoading} onClick={() => loadMore()}/>

            </div>
          </section>
        </>
      )}
    </>
  );
}

export default AllNews;