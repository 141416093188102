import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { AppDispatch } from "../../store/store";

import { changeToggle } from "../../store/scheduleStore";

import './Toggle.scss';

function ToggleSchedule() {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { toggleCheck, selectOptions } = useSelector((state: RootState) => state.schedule);

  const handleToggle = () => {
    dispatch(changeToggle({ toggle: !toggleCheck }));
  };

  useEffect(() => {
    if(selectOptions.length === 0) {
      return;
    }
    dispatch(changeToggle({ toggle: toggleCheck }));
    // eslint-disable-next-line
  }, [selectOptions]);

  return (
    <label className="toggle_container">
      <input type="checkbox" checked={toggleCheck} onChange={handleToggle}/>
      <div className="toggle">
        <span className={`toggle-text male ${toggleCheck ? '' : 'active'}`}>{t('man')}</span>
        <span className={`toggle-text female ${toggleCheck ? 'active' : ''}`}>{t('woman')}</span>
      </div>
    </label>
  );
}

export default ToggleSchedule;