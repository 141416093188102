import React from "react";

interface HeroImageProps {
  typeImage?: string;
}

function HeroImage({ typeImage }: HeroImageProps) {

  switch (typeImage) {
  case 'team':
    return <img className="hero-background-image" src={`${process.env.PUBLIC_URL}/assets/hero/team-hero.webp`}
    alt="hintergrund wasserball bild"/>;
  default:
    return <img className="hero-background-image" src={`${process.env.PUBLIC_URL}/assets/hero/hero-waba.webp`}
    alt="hintergrund wasserball bild"/>;
  }
}

export default HeroImage;