import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

import Header from "../../components/header/Header";
import FooterTeam from "../../components/footer/FooterTeam";
import Hero from "../../components/hero/Hero";
import TeamHeroImage from "../../components/hero/teamHero/TeamHeroImage";
import TeamInformation from "../../components/hero/teamHero/TeamInformation";
import HeroImage from "../../components/hero/HeroImage";

interface BaseLayoutProps {
  children: React.ReactNode;
}

function TeamLayout({ children }: BaseLayoutProps) {
  const { team } = useSelector((state: RootState) => state.teams);
    
  return (
    <>
      <Header>
        <a href="/">
          <img src={`${process.env.PUBLIC_URL}/assets/logos/logo-dieligadierockt@2x.webp`} alt="Wasserball Logo"
            width="238" height="94"/>
        </a>
      </Header>
      <Hero customClass="hero-team">
        <>
          <HeroImage typeImage="team"/>
          <TeamHeroImage picture={team.team.headerimage} headerimage_copyright_text={team.team.headerimage_copyright_text}/>
          <TeamInformation
            logo={team.team.logo }
            name = { team.team.name }
            address = { team.team.address }
            zip = { team.team.zip }
            city = { team.team.city }
            email = { team.team.email }
            phone = { team.team.phone }
            website = { team.team.website }
            description = { team.team.aboutcompany }
          />
        </>
      </Hero>
      <main>
        {children}
      </main>
      <FooterTeam
        name = { team.team.name }
        address = { team.team.address }
        zip = { team.team.zip }
        city = { team.team.city }
        email = { team.team.email }
        phone = { team.team.phone }
        website = { team.team.website }
        map = { team.team.googlemaplink }
      />
    </>
  );
}

export default TeamLayout;