import React from 'react';
import { useDispatch } from "react-redux";

import { openLightBox } from '../../store/configStore';
import useBodyScrollLock from '../../hooks/useBodyScrollLock';
import useKey from "../../hooks/useKey";

import './Lightbox.scss';

interface LightboxProps {
  customClass?: string;
  children?: React.ReactNode;
}

function Lightbox({ customClass = '', children }: LightboxProps) {
  const dispatch = useDispatch();
  useBodyScrollLock();

  function closeLightBox() {
    dispatch(openLightBox(false));
  }

  useKey(closeLightBox);

  return (
    <section className={`module_lightbox ${customClass}`}>
      <div className="lightbox-close-body" onClick={() => closeLightBox()}></div>
      <div className="container">
        <div className="lightbox-close">
          <span className="lightbox-close-button" onClick={() => closeLightBox()}></span>
        </div>
        <div className="lightbox-container">
          { children }
        </div>
      </div>
    </section>
  );
}
export default Lightbox;