import React from 'react';
import LiveTickerLayout from "../layouts/baseLayout/LiveTickerLayout";
import LiveTicker from "../components/liveTicker/LiveTicker";

function LiveTickerSite() {

  return (
    <LiveTickerLayout customHeroClass="popup-hero" isNavigation={false} showUpToButton={true}>
      <LiveTicker />
    </LiveTickerLayout>
  );
}

export default LiveTickerSite;