import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";

import TeamCardItem from "./TeamCardItem";
import Anker from "../Anker/Anker";

import Pagination from "../pagination/Pagination";
import { initTeamPagination, teamPagination } from "../../store/paginationStore";
import { setData, setSelectOptions } from "../../store/teamsStore";

import Toggle from "../toogle/Toggle";


import './TeamCards.scss';
import CustomTeamSelect from "../select/CustomTeamSelect";

interface TeamCard {
  _id: string;
  index: number;
  guid: string;
  name: string;
  image: string;
  link: string;
}

function TeamCards({ anker }: { anker?: string }) {
  const dispatch = useDispatch();
  const { allTeamsData, allTeamsOptions } = useSelector((state: RootState) => state.fetchData);
  const { teamPaginationValue } = useSelector((state: RootState) => state.pagination);
  const { toggle, teamData, selectIndex, selectOptions = [] } = useSelector((state: RootState) => state.teams);

  const [teamCardData, setTeamCardData] = useState<TeamCard[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (allTeamsData === undefined || allTeamsData.length === 0) {
      return;
    }

    const cachedData = allTeamsData[toggle] || [];
    const filteredTeamsLeague = cachedData[selectIndex]._id || [];
    const filteredTeams = cachedData[selectIndex].data || [];

    dispatch(initTeamPagination(filteredTeams.length));
    dispatch(setData({ teams: filteredTeams, league: filteredTeamsLeague }));
  }, [allTeamsData, dispatch, selectIndex, toggle]);

  useEffect(() => {
    dispatch(setSelectOptions(allTeamsOptions[toggle]));
  }, [allTeamsOptions, dispatch, toggle]);

  useEffect(() => {
    if (teamData === undefined || teamData.length === 0) {
      return;
    }

    if (teamPaginationValue.now === 0) {
      setTeamCardData(teamData.slice(0, teamPaginationValue.count));
      return;
    }

    setTeamCardData(teamData.slice(teamPaginationValue.now * teamPaginationValue.count, (teamPaginationValue.now + 1) * teamPaginationValue.count));
  }, [teamData, teamPaginationValue.count, teamPaginationValue.now]);

  return (
    <>
      <Anker anker={anker}/>
      <section className="module_team-cards">
        <div className="container">
          <div className="title-filter-container">
            <h2>{t('title.teams')}</h2>
            {selectOptions.length > 0 && (
              <div className="filter-container">
                <Toggle/>
                <CustomTeamSelect customClass="custom-select-container"/>
              </div>
            )}
          </div>

          {teamCardData.length > 0 && (
            <>
              <div className="team-card-container">
                {teamCardData.map((item) => (
                  <TeamCardItem key={uuidv4()} image={item.image} name={item.name} link={item.link}/>
                ))}
              </div>

              {teamPaginationValue.showPagination && (
                <Pagination dataAction={teamPagination} state={teamPaginationValue}/>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default TeamCards;