import React from "react";
import { useTranslation } from "react-i18next";

import { CadreTeamInfoProps } from './Cadre.types';

function CadreTeamInfo({ captain, tutor, coach, teamSupport, customClass = '' }: CadreTeamInfoProps) {
  const { t } = useTranslation();

  return (
    <div className={`cadre-team-info-container ${customClass}`}>
      <div className="cadre-team-info">
        <span>{t('live-panel.captain')}: </span>
        {captain}
      </div>

      <div className="cadre-team-info">
        <span>{t('live-panel.coach')}: </span>
        {coach}
      </div>

      <div className="cadre-team-info">
        <span>{t('live-panel.tutor')}: </span>
        {tutor}
      </div>

      <div className="cadre-team-info">
        <span>{t('live-panel.team-support')}: </span>
        {teamSupport}
      </div>
    </div>
  );
}

export default CadreTeamInfo;