import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";

import { openLightBox, newsRoomId } from "../../store/configStore";
import useFormatDays from "../../hooks/useFormatDays";

interface NewsItemProps {
  id: string | number;
  customClass?: string;
  date?: any;
  type: string;
  title: string;
  intro: string;
  image: string;
  newsTeam: string;
  copyRight?: string;
}

function NewsItem({ id, customClass, date = '', type, title, intro, image, newsTeam, copyRight }: NewsItemProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function openLightBoxFunc() {
    dispatch(newsRoomId(String(id)));
    dispatch(openLightBox(true));
  }

  const formattedDate = useFormatDays(date);

  return (
    <button className={`news-item-container ${customClass}`} onClick={() => openLightBoxFunc()}>
      <div className="news-item-content">
        <div className="news-item-type">
          {date ?
            <span className="date">{formattedDate}</span>
            :
            <span className="date"></span>
          }
          <span className="type">{t(`news.${type}`)}</span>
        </div>
        <h3>
          {title}
        </h3>
        <div className="news-intro" dangerouslySetInnerHTML={{ __html: intro }}/>

        {newsTeam &&
        <div className="video-stream">
          {t('link-video-steam')}
        </div>
        }
      </div>
      {image && <img src={image} alt={title}/>}
      {copyRight && <p className="copyright">{copyRight}</p>}
    </button>
  );
}

export default NewsItem;