import React from 'react';

import './Team.scss';

interface TeamProps {
  logoHome: string;
  teamHome: string | undefined;
  logoGuest: string;
  teamGuest: string | undefined;
  customClass?: string;
}

function Team({ logoHome, teamHome, logoGuest, teamGuest, customClass = "" }: TeamProps) {

  return (
    <div className={`teamContainer ${customClass}`}>
      <div className="homeTeam">
        <span>{teamHome}</span>
        {logoHome ?
          <img src={logoHome} alt={`logo ${teamHome}`} height={36} width={36} />
          :
          <img src="../../../assets/placeholder/placeholder-800.webp" alt={`logo ${teamHome}`} height={36} width={36}/>
        }
      </div>

      <div className="vs">
        vs.
      </div>

      <div className="guestTeam">
        {logoGuest ?
          <img src={logoGuest} alt={`logo ${teamGuest}`} height={36} width={36} />
          :
          <img src="../../../assets/placeholder/placeholder-800.webp" alt={`logo ${teamGuest}`} height={36} width={36} />
        }
        <span>{teamGuest}</span>
      </div>
    </div>
  );
}

export default Team;