import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import './Pagination.scss';


interface PaginationProps {
  dataAction: any;
  state: any;
}

function Pagination({ dataAction, state }:PaginationProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function nextPagination() {
    dispatch(dataAction('increment'));
  }

  function prevPagination() {
    dispatch(dataAction('decrement'));
  }

  return (
    <div className="pagination-container">
      <button className="pagination prev" aria-label={t('prev-aria-label')} disabled={state.prevDisabled}
        onClick={() => prevPagination()}></button>

      {/*/!*<button className="pagination" value="1" onClick={handlePagination}>1</button>*!/*/}
      {/*/!*<button className="pagination" value="2" onClick={handlePagination}>2</button>*!/*/}
      {/*/!*<button className="pagination" value="3" onClick={handlePagination}>3</button>*!/*/}
      {/*/!*<button className="pagination" onClick={() => setTeamCardData(teamCards.slice(0, 12))}>...</button>*!/*/}

      <button className="pagination next" aria-label={t('next-aria-label')} disabled={state.nextDisabled}
        onClick={() => nextPagination()}></button>
    </div>
  );
}

export default Pagination;