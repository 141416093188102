import React from "react";
import { useTranslation } from "react-i18next";

declare const Cookiebot: any;

interface YouTubeEmbedProps {
  videoId: string;
  title: string;
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ videoId, title }) => {
  const { t } = useTranslation();
  const videoSrc = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="video">
      <div className="cookieconsent-optin-marketing" >
        <iframe
          width="100%"
          height="100%"
          src={videoSrc}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
      <div className="cookieconsent-optout-marketing">
        <button onClick={() => Cookiebot.renew()}>{t('cookies.video')}</button>
      </div>
    </div>
  );
};

export default YouTubeEmbed;