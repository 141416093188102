import React from "react";

interface BaseNewsLayoutProps {
  children: React.ReactNode;
  customClass?: string;
}

function BaseNewsLayout({ children, customClass = '' } : BaseNewsLayoutProps) {

  return (
    <div className={`news-lightbox-container ${customClass}`}>
      { children }
    </div>
  );
}

export default BaseNewsLayout;