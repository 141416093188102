import React from "react";

interface TeamHeroImageProps {
  picture: string;
  headerimage_copyright_text: string
}

function TeamHeroImage({ picture, headerimage_copyright_text = "" }: TeamHeroImageProps) {;
  return (
    <div className={`hero-team-image ${picture? '' : 'team-hero-empty'}`}>
      {picture &&
        <img src={picture} width={900} height={543} alt="team hero"/>
      }
      
       {headerimage_copyright_text && (
            <figcaption>{headerimage_copyright_text}</figcaption>
      )}
    </div>
  );
}

export default TeamHeroImage;