import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { RootState } from './store/store';

import RouteWithTitle from './hooks/RouteWithTitle';
import Home from './pages/Home';
import Team from './pages/Team';
import NotFoundSite from "./pages/NotFoundSite";
import Imprint from "./pages/Imprint";
import DataPrivacy from "./pages/DataPrivacy";
import LiveTicker from "./pages/LiveTicker";
import LiveStream from "./pages/LiveStream";

import { AppDispatch } from './store/store';
import {
  loadTeamsData,
  loadNewsData,
} from './store/fetchActionStore';
import {
  loadScheduleOptions,
  loadWeekDayData,
  loadTableData,
  loadAllScheduleData,
  loadAllBestData
} from './store/scheduleStore';

function App() {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { teamSiteName } = useSelector((state: RootState) => state.teams);

  useEffect(() => {
    dispatch(loadWeekDayData());
    dispatch(loadTeamsData());
    dispatch(loadNewsData());
    dispatch(loadScheduleOptions());
    dispatch(loadTableData());
    dispatch(loadAllScheduleData());
    dispatch(loadAllBestData());
  }, [dispatch]);


  return (
    <Routes>
      <Route path="/" element={
        <RouteWithTitle title={t('titleSites.home')}>
          <Home/>
        </RouteWithTitle>
      }/>
      <Route path="/team/:teamName" element={
        <RouteWithTitle title={teamSiteName}>
          <Team/>
        </RouteWithTitle>
      }/>
      <Route path="/game/:gameId" element={
        <RouteWithTitle title={t('titleSites.live-ticker')}>
          <LiveTicker/>
        </RouteWithTitle>
      }/>
      <Route path="/stream/:streamId" element={
        <RouteWithTitle title={t('live-stream')}>
          <LiveStream/>
        </RouteWithTitle>
      }/>
      <Route path="/impressum" element={
        <RouteWithTitle title={t('titleSites.imprint')}>
          <Imprint/>
        </RouteWithTitle>
      }/>
      <Route path="/datenschutz" element={
        <RouteWithTitle title={t('titleSites.privacy')}>
          <DataPrivacy/>
        </RouteWithTitle>
      }/>
      <Route path="*" element={
        <RouteWithTitle title={t('titleSites.notFound')}>
          <NotFoundSite/>
        </RouteWithTitle>
      }/>
    </Routes>
  );
}

export default App;