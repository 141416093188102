import React from "react";

import NewsItemLayout from "../../../layouts/newsLayouts/NewsItemLayout";
import Default from "../../../layouts/newsLayouts/Default";
import BaseNewsLayout from "../../../layouts/newsLayouts/BaseNewsLayout";
import NewsVideoLayout from "../../../layouts/newsLayouts/NewsVideoLayout";

import "./NewsLightBox.scss";
import NewsAudioLayout from "../../../layouts/newsLayouts/NewsAudioLayout";
import NewsWhitepaperLayout from "../../../layouts/newsLayouts/NewsWhitepaperLayout";

function NewsLightBox(props: any) {
  const { type, date, image, title, intro, description, file, youtubelink, image_copyright_text } = props.data;

  switch (type) {
  case "whitepaper":
    return (
      <BaseNewsLayout customClass="whitepaper">
        <NewsWhitepaperLayout type={ type } date={ date } image={ image } title={ title } intro={ intro } description={ description } link={ file }/>
      </BaseNewsLayout>
    );
  case "podcast":
    return (
      <BaseNewsLayout customClass="podcast">
        <NewsAudioLayout type={ type } date={ date } image={ image } title={ title } intro={ intro } description={ description } podcast={ file } image_copyright_text={image_copyright_text} />
      </BaseNewsLayout>
    );
  case "article":
    return (
      <BaseNewsLayout customClass="article">
        <NewsItemLayout type={ type } date={ date } image={ image }  title={ title } intro={ intro } description={ description } image_copyright_text={image_copyright_text} />
      </BaseNewsLayout>
    );
  case "video":
    return (
      <BaseNewsLayout customClass="video">
        <NewsVideoLayout type={ type } date={ date } image={ image } title={ title } intro={ intro } description={ description } video={ file } youtube={ youtubelink } image_copyright_text={image_copyright_text} />
      </BaseNewsLayout>
    );
  case "stream":
    return (
      <BaseNewsLayout customClass="stream">
        <NewsVideoLayout type={ type } date={ date } image={ image } title={ title } intro={ intro } description={ description } video={ file } youtube={ youtubelink } image_copyright_text={image_copyright_text} />
      </BaseNewsLayout>
    );
  default:
    return (
      <BaseNewsLayout>
        <Default  />
      </BaseNewsLayout>
    );
  }
}

export default NewsLightBox;