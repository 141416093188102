import React from "react";
import { useTranslation } from "react-i18next";
import UpToButtom from "../upToButton/UpToButtom";
import FooterBottom from "./FooterBottom";

import "./Footer.scss";

declare const Cookiebot: any;

interface TeamInformationProps {
  name?: string;
  address?: string;
  zip?: string;
  city?: string;
  email?: string;
  phone?: string;
  website?: string;
  map?: string;
}

function FooterTeam({ name ='', address ='', zip ='', city ='', email ='', phone ='', website ='', map ='' }: TeamInformationProps) {
  const { t } = useTranslation();

  return (
    <footer className="module_footer-team">
      <UpToButtom/>
      <div className="footer-wrapper">
        <div className="container">
          <div className="team-adress">
            {name && <h4>{name}</h4>}
            <ul>
              {name && <li>{name}</li>}
              {address && <li>{address}</li>}
              {zip && city && <li>{zip} {city}</li>}
              {email && <li><a href={`mailTo: ${email}`}>{email}</a></li>}
              {phone && <li><a href={`tel: ${phone}`}>{phone}</a></li>}
              {website && (
                <li>
                  <a href={website.startsWith('http://') || website.startsWith('https://') ? website : `http://${website}`} target="_blank" rel="noreferrer">
                    {website}
                  </a>
                </li>
              )}
            </ul>
          </div>

          {map && (
            <div className="map">
              <div className="cookieconsent-optin-marketing" >
                <iframe
                  title="Team Location Map"
                  src={map}
                  width="100%" height="100%" loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
              <div className="cookieconsent-optout-marketing" >
                <button onClick={() => Cookiebot.renew()}>{t('cookies.map')}</button>
              </div>

            </div>
          )}
        </div>
      </div>
      <FooterBottom/>
    </footer>
  );
}

export default FooterTeam;