import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";

import HamburgerMenu from "./HamburgerMenu";
import { openNavi } from "../../store/navigationStore";

import "./Header.scss";

interface HeaderProps {
  children?: React.ReactNode;
  isNavigation?: boolean;
}

function Header({ children, isNavigation = true }: HeaderProps) {
  const { isOpenNavigation } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState('');

  function openMenu() {
    if (isOpenNavigation) {
      dispatch(openNavi(''));
      setIsOpen('');
    } else {
      dispatch(openNavi('open'));
      setIsOpen('open');
    }
  }

  return (
    <header>
      <div className="container">
        <div className="header-container">
          <div className="logo">
            {children}
          </div>
          {isNavigation &&
          <>
            <nav>
              <button className={`empty menu-close ${isOpen}`} onClick={() => openMenu()} aria-label="Navigations Menü"></button>
            </nav>
            <HamburgerMenu customClass={isOpenNavigation}/>
          </>
          }
        </div>
      </div>
    </header>
  );
}

export default Header;