import React from "react";
import { useTranslation } from "react-i18next";

import useFormatDays from "../../hooks/useFormatDays";

interface NewsAudioProps {
  type: string;
  date?: any;
  title: string;
  image?: string;
  intro: string;
  description: string;
  podcast?: string;
  image_copyright_text?: string;
}


function NewsAudioLayout({ type, date = '', title, image, intro, description, podcast, image_copyright_text = "" }: NewsAudioProps) {
  const { t } = useTranslation();
  const formattedDate = useFormatDays(date);

  return (
    <>
      <div className="news-type icon">{t(`news.${type}`)}</div>

      {date ?
        <div className="news-date">{formattedDate}</div>
        :
        <div className="news-date"></div>
      }

      {image && (
        <div className="news-image">
          <img src={image} alt={title}/>
          {image_copyright_text && (
            <figcaption>{image_copyright_text}</figcaption>
          )}
        </div>
      )}

      <h1>{title}</h1>

      {description && (
        <div className="news-description">
          <div className="news-intro" dangerouslySetInnerHTML={{ __html: intro }}/>
          <div dangerouslySetInnerHTML={{ __html: description }}/>
        </div>
      )}

      {podcast && (
        <>
          <audio className="audio" controls>
            <source src={podcast} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </>
      )}

    </>
  );
}

export default NewsAudioLayout;