import React from 'react';

import './UpToButton.scss';

interface UpToButtonProps {
  customClass?: string;
}

function UpToButton({ customClass = "" }: UpToButtonProps) {

  function upTo() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <span data-testid="upToButtom" className={`up-to-button ${customClass}`} onClick={() => upTo()}>
      <img src={`${process.env.PUBLIC_URL}/assets/icons/btn-top.svg`} alt="UpToTopButton" width={61} height={61} />
    </span>
  );
}

export default UpToButton;