import React from "react";

import "./Hero.scss";

interface HeroProps {
  children?: React.ReactNode;
  customClass?: string;
}

function Hero({ children, customClass }: HeroProps) {

  return (
    <section className={`module_hero ${customClass}`}>
      <div className="container">
        {children}
      </div>
    </section>
  );
}

export default Hero;