import React from "react";
import { useTranslation } from "react-i18next";

import BaseLayout from "../layouts/baseLayout/BaseLayout";

function Imprint() {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <section className="module_legal-notice">
        <div className="container">
          <h1 className="h2">{t('titleSites.imprint')}</h1>

          {data[0] && data[0].content.map((content, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: content }}></div>
          ))}

        </div>
      </section>
    </BaseLayout>
  );
}

export default Imprint;

const data = [
  {
    "titleSites": {
      "imprint": "imprint"
    },

    "content": [
      "<p><strong>Deutscher Schwimm-Verband e.V.</strong><br>Korbacher Str. 93<br>34132 Kassel<br> <br>Telefon +49 (0)561/9 40 83-0<br>Telefax +49 (0)561/9 40 83-15<br><a href='mailTo: info@dsv.de'>info@dsv.de</a></p>" +
      "<p><strong>Vertretungsberechtigte</strong><br> Der Verband wird durch die Vizepräsidenten Wolfgang Rupieper und Kai Morgenroth gemeinsam vertreten.</p>" +
      "<p><strong>Registergericht</strong><br>Amtsgericht Kassel<br>Vereinsregisternummer: 850 VR 2744<br>Umsatzsteuer-Identifikationsnummer: DE 170 385 217<br></p>" +
      "<p><strong>Quellenangaben für die verwendeten Bilder und Grafiken:</strong></p>" +
      "<p><strong>Fotografen:</strong><br>Benjamin Lau<br>Florian Schwarzenbach<br>Mirko Seifert<br>Lennart Löscher<br>Diverse Fotografen<br></p>" +
      "<p>Grafiken erstellt durch Canva & Grafikgenerator</p>" +
      "<p><strong>Haftungsausschluss</strong><br>Mit dem Betrachten dieser Internetseite erklären Sie sich mit dem Inhalt des nachfolgend formulierten Haftungsausschlusses einverstanden.</p>" +
      "<p><strong>Inhalt der Internetseite</strong><br>Der DSV bzw. die Verfasser einzelner Teile dieser Internetseite (nachfolgend: Autoren) übernehmen keine Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereit gestellten Informationen. Soweit auf dieser Internetseite Veröffentlichungen erfolgen, die nach der Satzung oder den Ordnungen des DSV einer Veröffentlichung im amtlichen Organ bedürfen, gilt ausschließlich der im amtlichen Organ veröffentlichte Text. Haftungsansprüche gegen den DSV oder die Autoren wegen Schäden materieller oder ideeller Art, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und/oder unvollständiger Informationen verursacht werden, sind grundsätzlich ausgeschlossen, es sei denn, dem DSV oder den Autoren fällt nachweislich vorsätzliches oder grob fahrlässiges Verhalten zur Last.</p>" +
      "<p>Alle eventuell auf dieser Internetseite unterbreiteten Angebote sind freibleibend und unverbindlich. Der DSV und die Autoren behalten sich ausdrücklich vor, diese Internetseite ganz oder teilweise ohne besondere Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder auf Dauer einzustellen.</p>" +
      "<p><strong>Verweise und Links</strong><br>Nach der Rechtsprechung tritt bei direkten oder indirekten Verweisen auf fremde Internetseiten (Links) die außerhalb des Verantwortungsbereichs des DSV oder der Autoren liegen, eine Haftung ausschließlich ein, wenn der DSV oder die Autoren von den Inhalten Kenntnis haben und es ihnen technisch möglich und zumutbar (gewesen) wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der DSV und die Autoren erklären ausdrücklich, dass zum Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei von illegalen Inhalten waren. Der DSV und die Autoren haben keinerlei Einfluss auf die aktuelle oder eine zukünftige Gestaltung und auf die Inhalte von verlinkten/verknüpften Seiten. Sie distanzieren sich deshalb ausdrücklich von allen Inhalten aller verlinkten/verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge insbesondere in eingerichteten Gästebüchern, Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung von dergestalt dargebotenen Informationen entstehen, haftet allein der Anbieter der Seite, auf die verwiesen wurde, und nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.</p>" +
      "<p><strong>Urheber- und Kennzeichenrecht</strong><br>Der DSV und die Autoren sind bemüht, in allen Veröffentlichungen die Urheberrechte Dritter an verwendeten Grafiken, Dokumenten, Tondokumenten etc. zu beachten und insbesondere möglichst nur selbst erstellte Grafiken, Dokumente, Tondokumente etc. zu nutzen oder auf lizenzfreie Grafiken, Dokumente, Tondokumente etc. zurückzugreifen. Alle innerhalb dieses Internetangebotes genannten/verwendeten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen ohne Einschränkung den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitz- und Verwertungsrechten der jeweiligen eingetragenen Eigentümer. Die bloße Nennung dieser Marken- und Warenzeichen rechtfertigt nicht den Schluss, dass diese nicht durch Rechte Dritter geschützt sind. Das Copyright für veröffentlichte, vom DSV oder anderen Autoren selbst erstellte Objekte bleibt allein beim DSV bzw. den Autoren der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Dokumente, Tondokumente etc. in anderen elektronischen oder gedruckten Veröffentlichungen ist nur mit ausdrücklicher schriftlicher Zustimmung des DSV bzw. der Autoren erlaubt.</p>" +
      "<p><strong>Rechtswirksamkeit des Haftungsausschluss</strong><br>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von wem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen, bleiben die übrigen Regelungen hiervon unberührt.</p>" +
      "<p><strong>Datenschutz</strong><br><a href='/datenschutz'>Unsere Datenschutzerklärung</a></p>"
    ]
  }
];