import React from 'react';

function defaultLayout() {
  return (
    <div className="news-lightbox-container">
      <h1>Tut mir leid, Layout wird noch für diese Kategorie erstellt!</h1>

      <div className="news-description">
        <p>Whitepaper ist schon erstellt und funktioniert.</p>
      </div>
    </div>
  );
}

export default defaultLayout;

