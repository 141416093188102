import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from 'react-i18next';

import FooterBottom from "./FooterBottom";
import "./Footer.scss";

interface FooterProps {
  children?: React.ReactNode;
  hidden?: boolean;
}

interface NavigationProps {
  _id: string;
  title: string;
  link: string;
  customClass?: string;
}

function Footer({ children, hidden = false }: FooterProps) {
  const { t } = useTranslation();
  const { isLiveGame, dsvInformation } = useSelector((state: RootState) => state.config);
  const { isLiveNavigation, footerMainNavigation } = useSelector((state: RootState) => state.navigation);

  return (
    <footer>
      {children}
      {!hidden &&
      <div className="footer-wrapper">
        <div className="container">
          <div className="grids">
            <div className="col-start-1 col-span-12 md:col-start-1 md:col-span-6 lg-min:col-start-1 lg-min:col-span-3 ">
              <div className="footer-navigation">
                <ul>
                  {isLiveGame &&
                    <li><a href={isLiveNavigation[0].link}>{t('mainNavigation.livestreams')}</a></li>
                  }
                  {footerMainNavigation && footerMainNavigation.map((item: NavigationProps) => (
                    <li key={item._id}><a href={item.link}>{t(`mainNavigation.${item.title}`)}</a></li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-start-1 col-span-12 md:col-start-7 md:col-span-6 lg-min:col-start-4 lg-min:col-span-3 ">
              <div className="footer-navigation">

              </div>
            </div>
            <div className="col-start-1 col-span-12 md:col-start-1 md:col-span-6 lg-min:col-start-7 lg-min:col-span-3">
              <div className="footer-navigation">

                {/*<ul>*/}
                {/*  <li>{t('navigation.press-contact')}</li>*/}
                {/*</ul>*/}
                {/*<div dangerouslySetInnerHTML={{ __html: mainInformation.address }}/>*/}

                {/*<p><a href={`mailTo: ${mainInformation.email}`}>{t(`navigation.${mainInformation.emailText}`)}</a>*/}
                {/*  <br/>*/}
                {/*  <a href={`tel: ${mainInformation.phone}`}>{t(`navigation.${mainInformation.phoneText}`)}</a>*/}
                {/*</p>*/}

              </div>
            </div>
            <div className="col-start-1 col-span-12 md:col-start-7 md:col-span-6 lg-min:col-start-10 lg-min:col-span-3">
              <div className="footer-navigation">
                <a href={dsvInformation.link}>
                  <picture>
                    <img src={dsvInformation.logo} alt={dsvInformation.alt} height="68" width="147"/>
                  </picture>
                </a>
                <div dangerouslySetInnerHTML={{ __html: dsvInformation.address }}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      <FooterBottom/>
    </footer>
  );
}

export default Footer;