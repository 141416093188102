import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import TeamItem from "../liveTicker/MatchTeams/TeamItem";
import CadreItem from "./CadreItem";

import "./Cadre.scss";

interface TeamDataProps {
  home: string;
  guest: string;
  picture: string;
  customClass: string;
}

interface SquadDataProps {
  uid: number;
  tstamp: number;
  crdate: number;
  fullname: string;
  nationality: string;
  position: string;
  nationalityCode: string;
  birthday: string;
  jersayNumber: string | number;
  _uid: string;
  goals: number | null;
  errors: number | null;
  ame: number | null;
}

interface SquadProps {
  _id: string;
  captain: string;
  coach: string;
  tutor: string;
  teamSupport: string;
  leiter: string;
  betreuer: string;
  wip: string;
  squad: SquadDataProps[];
}

interface SquadsDataProps {
  teamData: TeamDataProps[];
  squadHome: SquadProps[];
  squadGuest: SquadProps[];
}

function Cadre({ teamData, squadHome, squadGuest }: SquadsDataProps) {
  const [suqadTeamHome, setSquadTeamHome] = useState<SquadProps[]>([]);
  const [suqadTeamGuest, setSquadTeamGuest] = useState<SquadProps[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const sortedSquadHome = squadHome.map((squad) => ({
      ...squad,
      squad: squad.squad.sort((a: SquadDataProps, b: SquadDataProps) => Number(a.jersayNumber) - Number(b.jersayNumber)),
    }));
    const sortedSquadGuest = squadGuest.map((squad) => ({
      ...squad,
      squad: squad.squad.sort((a: SquadDataProps, b: SquadDataProps) => Number(a.jersayNumber) - Number(b.jersayNumber)),
    }));
    setSquadTeamHome(sortedSquadHome);
    setSquadTeamGuest(sortedSquadGuest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="module_cadre">
      <h2>{t('live-panel.teams')}</h2>
      <div className="cadre-container">
        <div className={`cadre-item-container team-home`}>
          <TeamItem teamName={teamData[0].home} teamLogo={teamData[0].picture}  />
          {suqadTeamHome.map((item) => (
            <CadreItem
              key={uuidv4()}
              cadre={item.squad}
              captain={item.captain}
              coach={item.coach}
              tutor={item.leiter}
              teamSupport={item.betreuer}
              wip={item.wip}
              customClass="team-home"
            />
          ))}
        </div>

        <div className={`cadre-item-container team-guest`}>
          <TeamItem teamName={teamData[1].guest} teamLogo={teamData[1].picture}  />
          {suqadTeamGuest.map((item) => (
            <CadreItem
              key={uuidv4()}
              cadre={item.squad}
              captain={item.captain}
              coach={item.coach}
              tutor={item.leiter}
              teamSupport={item.betreuer}
              wip={item.wip}
              customClass="team-guest"
            />
          ))}
        </div>

      </div>

    </div>
  );
}

export default Cadre;
