import React, { useState, useEffect } from "react";

import "./Scores.scss";
import ScoreItem from "./ScoreItem";

interface GameTableProps {
  total: [string, string];
  first: [string, string];
  second: [string, string];
  third: [string, string];
  forth: [string, string];
}


interface ScoresProps {
  scoreData?: GameTableProps[] | any;
  status?: string;
  customClass?: string;
}

function Scores({ scoreData, status, customClass = "" }: ScoresProps) {

  const [scores, setScores] = useState<GameTableProps>({ total: ["", ""], first: ["", ""], second: ["", ""], third: ["", ""], forth: ["", ""] });

  useEffect(() => {
    if (scoreData.length === 0 || scoreData === undefined || scoreData === null) {
      return;
    }

    setScores(scoreData);
  }, [scoreData]);

  return (
    <div className={`scores-panel-container ${customClass}`}>
      <ul>
        <ScoreItem
          homeScore={scores.total[0]}
          guestScore={scores.total[1]}
          customClass={`live-score-${status}`}
        />
        <ScoreItem
          homeScore={scores.first[0]}
          guestScore={scores.first[1]}
          customClass=""
        />
        <ScoreItem
          homeScore={scores.second[0]}
          guestScore={scores.second[1]}
          customClass=""
        />
        <ScoreItem
          homeScore={scores.third[0]}
          guestScore={scores.third[1]}
          customClass=""
        />
        <ScoreItem
          homeScore={scores.forth[0]}
          guestScore={scores.forth[1]}
          customClass=""
        />
      </ul>
    </div>
  );
}

export default Scores;