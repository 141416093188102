import React from "react";

interface TeamCardItemProps {
  name: string;
  image: string;
  link: string;
}
function TeamCardItem({ name, image, link }: TeamCardItemProps) {

  return (
    <a href={link} className="team-card-item-container" >
      <div className="team-card-image">
        <img src={ image } width={300} height={300} alt={`team logo ${name}`}/>
      </div>
      <span>{ name }</span>
    </a>
  );
}

export default TeamCardItem;