import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

function FooterBottom() {
  const date = new Date();
  const { t } = useTranslation();
  const { copyRightCompany } = useSelector((state: RootState) => state.config);
  const { footerNavigation } = useSelector((state: RootState) => state.navigation);

  return (
    <div className="container">
      <div className="footer-bottom-container">
        <p>&copy; {date.getFullYear()} Copyright <span dangerouslySetInnerHTML={{ __html: copyRightCompany }} /></p>
        <ul>
          {footerNavigation && footerNavigation.map((item) => (
            <li key={item._id}><a href={item.link} target={item.target}>{t(`navigation.${item.title}`)}</a></li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default FooterBottom;