import React from "react";

import './placeholder.scss';

function Schedule() {
  const placeholders = Array(4).fill(null);

  return (
    <div className="placeholder-container">
      <div className="live-panel-container game-table all-schedule">
        {placeholders.map((_, index) => (
          <div key={index} className="live-panel-item">
            <div className="game-date-container">
              <div className="game-date_day placeholder-animation"></div>
              <div className="game-date_time placeholder-animation"></div>
              <div className="game-date_location placeholder-animation"></div>
            </div>
            <div className="teamContainer">
              <div className="homeTeam placeholder-animation"></div>
              <div className="vs placeholder-animation"></div>
              <div className="guestTeam placeholder-animation"></div>
            </div>
            <div className="scores-panel-container">
              <ul>
                <li className="live-score-undefined placeholder-animation"></li>
                <li className="placeholder-animation"></li>
                <li className="placeholder-animation"></li>
                <li className="placeholder-animation"></li>
                <li className="placeholder-animation"></li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Schedule;
