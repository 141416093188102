import React from "react";
import { useTranslation } from "react-i18next";

import useFormatDays from "../../hooks/useFormatDays";

interface NewsItemLayoutProps {
  type: string;
  date?: any;
  title: string;
  image?: string;
  intro: string;
  description: string;
  link?: string;
}

function NewsWhitepaperLayout({ type, date = '', title, image = '', intro, description, link }: NewsItemLayoutProps) {
  const { t } = useTranslation();
  const formattedDate = useFormatDays(date);

  return (
    <>
      <div className="news-type icon">{t(`news.${type}`)}</div>
      {date ?
        <div className="news-date">{formattedDate}</div>
        :
        <div className="news-date"></div>
      }

      {image && (
        <div className="news-image">
          <img src={image} alt={title}/>
        </div>
      )}

      <h1>{title}</h1>
      <div className="news-description">
        <div className="news-intro" dangerouslySetInnerHTML={{ __html: intro }}/>

        <div dangerouslySetInnerHTML={{ __html: description }}/>

        {link && (
          <a className="button" href={link} target="_blank" rel="noreferrer">{t('download-button')}</a>
        )}
      </div>

    </>
  );
}

export default NewsWhitepaperLayout;