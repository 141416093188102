import React from "react";
import { useTranslation } from 'react-i18next';

export interface GameData {
  status: string;
}

interface LivePanelProps {
  data: GameData[];
}

function LivePanelHead({ data }: LivePanelProps) {
  const { t } = useTranslation();

  return (
    <div className="live-panel-container live-layout">
      <div className="live-panel-item">
        <div className="live-gametime"></div>
        <div className="teamContainer">
        </div>
        <div className={`scores-panel-container scores-head`}>
          <ul>
            <li className={`live-score-${data[0].status}`}>{t('live-panel.live')}</li>
            <li>{t('live-panel.first')}</li>
            <li>{t('live-panel.second')}</li>
            <li>{t('live-panel.third')}</li>
            <li>{t('live-panel.fourth')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LivePanelHead;