import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import GameButtons from "../../components/panelCardsComponents/gameButtons/GameButtons";
import DatePanelHead from "../../components/panelCardsComponents/datePanelHead/DatePanelHead";
import GameTable from "../../components/gameTable/GameTable";
import Table from "../../components/panelCardsComponents/table/Table";
import TeamCadre from "../../components/cadre/TeamCadre/TeamCadre";
import Anker from "../../components/Anker/Anker";

interface TeamResultLayoutProps {
  url: string;
  orderNumber: number;
  teamsPage?: boolean;
}

type ActiveSection = 'schedule' | 'table' ;

function TeamResultLayout({ url, orderNumber, teamsPage }: TeamResultLayoutProps) {
  const { t } = useTranslation();
  const { team } = useSelector((state: RootState) => state.teams);
  const { allSchedule } = useSelector((state: RootState) => state.schedule);

  const [isActive, setIsActive] = useState<ActiveSection>('schedule');
  const [tableState, setTableState] = useState([]);
  const [isTableFetching, setIsTableFetching] = useState(false);
  const [scheduleDataState, setScheduleDataState] = useState([]);
  const [cadre, setCadre] = useState<any>([]);

  const changeSection = (event: ActiveSection) => {
    setIsActive(event);
  };

  useEffect(() => {
    const fetchCadreData = async () => {
      const cadreUrl = `${url}/storage/teams/${orderNumber}/cadre.json`;

      try {
        const response = await fetch(cadreUrl, { method: 'GET' });
        const data = await response.json();
        setCadre(data.sort((a: any, b: any) => a.positionId - b.positionId));
      } catch (error) {
        console.log('Error fetching team data:', error);
      }
    };

    fetchCadreData().then(() => {});
  }, [url, orderNumber]);

  useEffect(() => {
    if (allSchedule.length === 0 ) {
      return;
    }

    const cacheType = team.team.category;
    const teamType = allSchedule?.[cacheType];
    const clubId = team.team.clubId;
    const scheduleCacheData: any = [];
    
    if (teamsPage === true) {
      Object.values(teamType).forEach((allItems: any) => {
        if(allItems.showteam) {
          Object.values(allItems.data).forEach((items: any) => {
            Object.values(items).forEach((item: any) => {
              if (item.teams.some((team: any) => team.clubid === clubId )) {
                scheduleCacheData.push(item);
              }
            });
          });
        }
      });
    } else {
      Object.values(teamType).forEach((allItems: any) => {
        Object.values(allItems.data).forEach((items: any) => {
          Object.values(items).forEach((item: any) => {
            if (item.teams.some((team: any) => team.clubid === clubId )) {
              scheduleCacheData.push(item);
            }
          });
        });
      });
    }    

    setScheduleDataState(scheduleCacheData.sort((a: any, b: any) => a.time - b.time));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSchedule]);

  //TODO: CHANGE THIS, WHEN API WILL BE READY
  // useEffect(() => {
  //   const fetchScheduleData = async () => {
  //     const scheduleUrl = `${url}/storage/teams/${orderNumber}/schedule.json`;
  //     try {
  //       const response = await fetch(scheduleUrl, { method: 'GET' });
  //       const data = await response.json();
  //       setScheduleDataState(Object.values(data));
  //     } catch (error) {
  //       console.log('Error fetching schedule data:', error);
  //     }
  //   };
  //
  //   fetchScheduleData().then(() => {});
  // }, [url, orderNumber]);

  useEffect(() => {
    if(isTableFetching) {
      return;
    }

    const fetchTableData = async () => {
      const tableUrl = `${url}/storage/leagues/${team.team.subCategoryId}/league.json`;
      try {
        const response = await fetch(tableUrl, { method: 'GET' });
        const data = await response.json();
        setTableState(data);

        if (data.length === 0) {
          return;
        }

        const teamUid = team.team._id;
        data.forEach((item: any) => {
          if (item.uid === teamUid) {
            item.isActive = true;
          }
        });

        setIsTableFetching(true);
      } catch (error) {
        console.log('Error fetching table data:', error);
      }
    };

    fetchTableData().then(() => {});
  }, [isTableFetching, url, team.team.subCategoryId, team.team._id]);

  return (
    <>
      {cadre.length > 0 ?
        <section className="module_gamesResult-layout">
          <div className="container">
            <h2>{t('title.cadre')}</h2>
            <TeamCadre data={cadre}/>
          </div>
        </section>
        : <></>
      }

      {scheduleDataState.length > 0 || tableState.length > 0 ?
        <>
          <Anker anker="games" />
          <section className="module_gamesResult-layout">
            <div className="container">
              <h2>{t('title.gameResult')}</h2>

              <GameButtons
                scheduleButton={scheduleDataState.length > 0 ? [() => changeSection('schedule'), 'schedule'] : undefined}
                tableButton={tableState.length > 0 ? [() => changeSection('table'), 'table'] : undefined}
                showSelection={false}
                activeSection={isActive}
              />

              {scheduleDataState.length > 0 && isActive === 'schedule' ?
                <>
                  <DatePanelHead/>
                  <GameTable data={ scheduleDataState }/>
                </>
                : <></>
              }

              {tableState.length > 0 && isActive === 'table' ?
                <>
                  <Table data={ tableState } />
                </>
                : <></>
              }
            </div>
          </section>
        </>
        : <></>
      }
    </>
  );
}

export default TeamResultLayout;