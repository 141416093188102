import React from "react";
import { useTranslation } from "react-i18next";

import useFormatDays from "../../hooks/useFormatDays";

interface NewsItemLayoutProps {
  type: string;
  date?: any;
  title: string;
  intro: string;
  image?: string;
  description: string;
  image_copyright_text: string;
}

function NewsItemLayout({ type, date = '', title, intro, image = '', description, image_copyright_text = "" }: NewsItemLayoutProps) {
  const { t } = useTranslation();
  const formattedDate = useFormatDays(date);

  return (
    <>
      <div className="news-type icon">{t(`news.${type}`)}</div>
      {date ?
        <div className="news-date">{formattedDate}</div>
        :
        <div className="news-date"></div>
      }

      {image && (
        <div className="news-image">
          <img src={image} alt={title}/>
          {image_copyright_text && (
            <figcaption>{image_copyright_text}</figcaption>
          )}
        </div>
      )}

      <h1>{title}</h1>

      <div className="news-description">
        <div className="news-intro" dangerouslySetInnerHTML={{__html: intro}}/>
        <div dangerouslySetInnerHTML={{__html: description}}/>
      </div>

    </>
  );
}

export default NewsItemLayout;