import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";

import { RootState } from "../../store/store";
import { setSelectIndex } from "../../store/teamsStore";

import { customStyles } from "./style";
import './CustomSelect.scss';

interface Option {
  value: string;
  label: string;
  index: number;
}

interface CustomScheduleSelectProps {
  customClass?: string;
}

function CustomTeamSelect({ customClass = '' }: CustomScheduleSelectProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectOptions, selectIndex } = useSelector((state: RootState) => state.teams);
  const [selectedOption, setSelectedOption] = useState<Option | null>(selectOptions[0] || null);

  useEffect(() => {
    setSelectedOption(selectOptions[selectIndex] || null);
  }, [selectOptions, selectIndex]);

  function changeOptions(value: SingleValue<Option>) {
    if (value) {
      const index = value.index;
      dispatch(setSelectIndex(index));
      setSelectedOption(selectOptions[index] || null);

    } else {
      setSelectedOption(null);
    }
  }

  return (
    <div className={`custom-select-container ${customClass}`}>
      <Select
        options={selectOptions}
        styles={customStyles}
        value={selectedOption}
        onChange={(value) => changeOptions(value)}
        placeholder={t('filter')}
        isSearchable={false}
        aria-label={t('team-label')}
      />
    </div>
  );
}

export default CustomTeamSelect;