import React, { useState, useEffect, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import MatchTeams from "./MatchTeams/MatchTeams";
import LiveTickerGame from "./LiverTickerGame/LiveTickerGame";
import LiveTickerItem from "./LiverTickerGame/LiveTickerItem";
import LiveTickerHead from "./LiverTickerGame/LiveTickerHead";
import Cadre from "../cadre/Cadre";
import GameStatistic from "../gameStatistic/GameStatistic";
import Button from "../buttons/Button";

import "./LiveTicker.scss";
import PopUp from "../PopUp/PopUp";
import { RootState } from "../../store/store";

interface LiveGameProps {
  _id: string;
  index: number;
  guid: string;
  quarter: string;
  time: string;
  isHomeTeam: boolean;
  isGuestTeam: boolean;
  player: string;
  event: string;
  eventLogo: string;
  score: string;
}

function LiveTicker() {
  const { gameId } = useParams<{ gameId: string }>();
  const { t } = useTranslation();
  const { baseUrl, liveTickerInterval } = useSelector((state: RootState) => state.config);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [liveTickerData, setLiveTickerData] = useState<any>([]);
  const [lastGoals, setLastGoals] = useState<any>([{ home: "0", guest: "0" }]);
  const [isHomeSquad, setIsHomeSquad] = useState<boolean>(false);
  const [isGuestSquad, setIsGuestSquad] = useState<boolean>(false);

  const fetchData = useCallback(async (order: number) => {
    const url = `${baseUrl}/storage/live/${order}/live.json`;

    try {
      const response = await fetch(url, { method: 'GET' });
      const data = await response.json();
      setLiveTickerData(data);
      setIsDataLoading(true);
    } catch (error) {
      console.log('Error fetching team data:', error);
    }
  }, [baseUrl]);

  useEffect(() => {
    if(isDataLoading) {
      return;
    }

    if (gameId) {
      fetchData(Number(gameId)).then(() => {});
    }

  }, [isDataLoading, fetchData, gameId]);

  useEffect(() => {
    if (!liveTickerData.isLive && isDataLoading) {
      return;
    }

    const intervalId = setInterval(() => {
      if (gameId) {
        fetchData(Number(gameId)).then(() => {});
      }
    }, liveTickerInterval);

    return () => clearInterval(intervalId);
  }, [isDataLoading, fetchData, liveTickerData.isLive, liveTickerInterval, gameId]);

  function closePopup() {
    window.close();
  }

  useEffect(() => {

    if(isDataLoading && liveTickerData.liveData && liveTickerData.liveData.length !== 0) {
      const lastGoal = liveTickerData.liveData[0].filter((event: { event: string; }) => event.event === "Tor").pop();

      if (lastGoal === undefined) {
        return;
      }

      const lastGoalHome = lastGoal.score.split(":")[0];
      const lastGoalGuest = lastGoal.score.split(":")[1];
      setLastGoals({ home: lastGoalHome, guest: lastGoalGuest });
      return;
    }

    setLastGoals({ home: "0", guest: "0" });

  }, [liveTickerData, isDataLoading]);

  useEffect(() => {
    if (!isDataLoading) {
      return;
    } 

    if (liveTickerData.squads && liveTickerData.squads.home && liveTickerData.squads.home[0].squad) {
      setIsHomeSquad(true);
    }

    if (liveTickerData.squads && liveTickerData.squads.guest && liveTickerData.squads.guest[0].squad) {
      setIsGuestSquad(true);
    }

  }, [isDataLoading, liveTickerData.squads]);


  return (
    <PopUp>

      {isDataLoading && liveTickerData.length !== 0 &&
        <div className="popup-logo-title">
          <div className="logos-container">
            <div className="logos">

              {/*{liveTickerData.leagueLogo &&*/}
              {/*  <img className="league-logo" src={`${process.env.PUBLIC_URL}${liveTickerData.leagueLogo}`}*/}
              {/*       alt='männer wasserball liga logo' height="40px" width="40px"/>*/}
              {/*}*/}

              <img className="league-logo" src={`${process.env.PUBLIC_URL}/assets/logos/signet-wasserball-maenner@2x.webp`}
                alt='männer wasserball liga logo' height="40px" width="40px"/>

              <img src={`${process.env.PUBLIC_URL}/assets/logos/Wasserball_Bundesliga_schriftzug@2x.webp`}
                alt="schriftzug wasserball liga" height="40px" width="150px"/>

            </div>
            {/*{isDataLoading && liveTickerData.protocol && liveTickerData.protocol !== "" && liveTickerData.protocol !== "/" ?*/}
            {/*  <a className="button" href={liveTickerData.protocol} target="_blank" rel="noreferrer">{t('download-protocol')}</a>*/}
            {/*  :*/}
            {/*  <a className="button disabled" href={liveTickerData.protocol} target="_blank" rel="noreferrer">{t('download-protocol')}</a>*/}
            {/*}*/}
          </div>
          <div className="popup-live-ticker">
            {liveTickerData.isLive &&
              <h2 className="h2">{t('live-panel.live-ticker')}</h2>
            }
            <h1 className="h4">{liveTickerData.league}</h1>
          </div>
        </div>
      }

      {isDataLoading && liveTickerData.length !== 0 &&
        <>
          <MatchTeams
            homeTeam={liveTickerData.teams[0].home}
            homeLogo={liveTickerData.teams[0].picture}
            guestTeam={liveTickerData.teams[1].guest}
            guestLogo={liveTickerData.teams[1].picture}
            homeGoal={lastGoals.home}
            guestGoal={lastGoals.guest}
            status={liveTickerData.status}
          />

          <div className={`ticker-score-container ${(liveTickerData.status==='live' || liveTickerData.status==='running') ? "live-score" : ""}`}>
            <span className="match-team team-home">{lastGoals.home}</span>
            <span>:</span>
            <span className="match-team team-guest">{lastGoals.guest}</span>
          </div>
        </>
      }

      <>
        {isDataLoading && liveTickerData.liveData && liveTickerData.liveData.length !== 0 && (
          <LiveTickerGame customClass="live-ticker-header">
            <LiveTickerHead/>
          </LiveTickerGame>
        )}

        {isDataLoading && liveTickerData.liveData && liveTickerData.liveData.length !== 0 &&
          liveTickerData.liveData.slice().reverse().map((items: LiveGameProps[]) => (
            <LiveTickerGame key={uuidv4()}>
              {items.slice().reverse().map((item: LiveGameProps) => (
                <LiveTickerItem
                  key={uuidv4()}
                  quarter={item.quarter}
                  time={item.time}
                  isHomeTeam={item.isHomeTeam}
                  isGuestTeam={item.isGuestTeam}
                  player={item.player}
                  event={item.event}
                  eventLogo={`${process.env.PUBLIC_URL}${item.eventLogo}`}
                  score={item.score}
                />
              ))}
            </LiveTickerGame>
          ))
        }
      </>

      {isDataLoading && liveTickerData.statystic && liveTickerData.statystic.length !== 0 &&
        <GameStatistic teamData={liveTickerData.teams} statisticData={liveTickerData.statystic}/>
      }

      {isDataLoading &&
        isHomeSquad &&
        isGuestSquad &&
        <Cadre teamData={liveTickerData.teams} squadHome={liveTickerData.squads.home}
          squadGuest={liveTickerData.squads.guest}/>
      }

      {/*<div className="button-element">*/}
      {/*  <div className="popup-download-protocol">*/}
      {/*    {isDataLoading && liveTickerData.protocol && liveTickerData.protocol !== "" && liveTickerData.protocol !== "/" ?*/}
      {/*      <a className="button" href={liveTickerData.protocol} target="_blank" rel="noreferrer">{t('download-protocol')}</a>*/}
      {/*      :*/}
      {/*      <a className="button disabled" href={liveTickerData.protocol} target="_blank" rel="noreferrer">{t('download-protocol')}</a>*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="button-element">
        <Button label={t('close')} onClick={() => closePopup()}/>
      </div>
    </PopUp>
  );
}

export default LiveTicker;
