import React from 'react';

import Team from "../panelCardsComponents/team/Team";
import Scores from "../panelCardsComponents/scores/Scores";
import GameDate from "../panelCardsComponents/date/GameDate";

import "./GameTable.scss";
import LiveButtons from "../panelCardsComponents/liveButtons/LiveButtons";



interface GameTableProps {
  _id: string;
  index: number;
  guid: string;
  teams: {
    home: string;
    picture: string;
    guest: string;
  }[];
  start: string;
  time: string;
  location: string;
  ticker: string;
  results: any[];
}

function GameTable({ data }: { data: GameTableProps[] }) {
  return (
    <div className="live-panel-container game-table all-schedule">
      {data && data.map((item) => (
        <div key={item._id} className="live-panel-item">
          <GameDate date={item.time} time={item.time} location={item.location}/>
          <Team logoHome={item.teams[0].picture} teamHome={item.teams[0].home}
            logoGuest={item.teams[1].picture} teamGuest={item.teams[1].guest}/>
          {item.results ?
            <Scores scoreData={item.results}/> : <></>
          }
          <LiveButtons tickerLinker={item.ticker} />
        </div>
      ))}
    </div>
  );
}

export default GameTable;