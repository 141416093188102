import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";

import { setToggle } from "../../store/teamsStore";
import { loadTeamStateToggle } from '../../store/teamsStore';

import './Toggle.scss';

function Toggle() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { toggleCheck } = useSelector((state: RootState) => state.teams);

  const handleToggle = () => {
    dispatch(setToggle(!toggleCheck));
  };

  useEffect(() => {
    const savedState = loadTeamStateToggle();
    if (savedState !== null) {
      dispatch(setToggle(savedState));
    }
  }, [dispatch]);

  return (
    <label className="toggle_container">
      <input type="checkbox" checked={toggleCheck} onChange={handleToggle}/>
      <div className="toggle">
        <span className={`toggle-text male ${toggleCheck ? '' : 'active'}`}>{t('man')}</span>
        <span className={`toggle-text female ${toggleCheck ? 'active' : ''}`}>{t('woman')}</span>
      </div>
    </label>
  );
}

export default Toggle;