import React from "react";
import { useTranslation } from "react-i18next";

interface LiveTickerHeadProps {
  customClass?: string;
}

function LiveTickerHead({ customClass }: LiveTickerHeadProps) {
  const { t } = useTranslation();

  return (
    <div className={`live-ticker-item-container ${customClass}`}>

      <div className="quarter">{t('live-panel.quarter')}</div>
      <div className="time">{t('live-panel.time')}</div>

      <div className="event-container home-team">
        <div className="player">{t('live-panel.player')}</div>
        <div className="event">{t('live-panel.event')}</div>
      </div>

      <div className="event-logo"></div>

      <div className="event-container guest-team">
        <div className="player">{t('live-panel.player')}</div>
        <div className="event">{t('live-panel.event')}</div>
      </div>
      <div className="score">{t('live-panel.score')}</div>

    </div>
  );
}

export default LiveTickerHead;