import React from "react";
import { useTranslation } from "react-i18next";

interface PopUpProps {
  children: React.ReactNode;
  customClass?: string;
}

function PopUp({ children, customClass = "" }: PopUpProps) {
  const { t } = useTranslation();
  function closePopup() {
    window.close();
  }

  return (
    <section className={`module_popup`}>
      <div className="container">
        <div className="popup-close">
          <button className="close" onClick={() => closePopup()} aria-label={`Schließe ${t('close-popup')}`}>
            <img src={`${process.env.PUBLIC_URL}/assets/icons/btn-close.svg`} alt={t('close-popup')} width={40} height={40}/>
          </button>
        </div>
        <div className="popup-wrapper">
          { children }
        </div>
      </div>
    </section>
  );
}

export default PopUp;