import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import CadreTableItem from "../CadreTableItem/CadreTableItem";
import CadreTableHead from "../CadreTableItem/CadreTableHead";

interface TeamCadreProps {
  uid: number;
  lastname: string;
  firstname: string;
  nationalityCode: string;
  position: string;
  jersayNumber: string;
  birthday: string;
  _uid: string;
}

function TeamCadre({ data } : { data: TeamCadreProps[] }) {
  const [width, setWidth] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  function setNewPosition(position: string) {
    if(width < 568) {
      switch (position) {
      case "Torwart":
        return "T";
      case "Centerverteidiger":
        return "CV";
      case "Linke Seite":
        return "LS";
      case "Rechte Seite":
        return "RS";
      case "Center":
        return "C";
      default:
        return "";
      }
    } else {
      return position;
    }
  }

  return (
    <div className="module_cadre team-cadre">
      <div className="cadre-container">
        <div className={`cadre-item-container team-home`}>
          <div className={`cadre-table-wrapper team-home`}>
            <CadreTableHead teamCadre={true} />
            {data && data.map((item:TeamCadreProps) => (
              <CadreTableItem
                key={item._uid}
                teamCadre={true}
                jerseyNumber={item.jersayNumber}
                player={item.lastname + ', ' + item.firstname}
                gamePosition={setNewPosition(item.position)}
                nation={item.nationalityCode}
                birthday={item.birthday}
              />
            ))}
          </div>
          <span className="legend">
            T = {t('positions.goalkeeper')},
            CV = {t('positions.centre-defender')},
            LS = {t('positions.left-side')},
            RS = {t('positions.right-side')},
            C = {t('positions.centre')}
          </span>
        </div>
      </div>
    </div>
  );
}

export default TeamCadre;