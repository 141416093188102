import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

import NavItem from "./NavItem";

function HamburgerMenu({ customClass }: { customClass: string }) {
  const { isLiveGame } = useSelector((state: RootState) => state.config);
  const { isLiveNavigation, mainNavigation } = useSelector((state: RootState) => state.navigation);

  return (
    <div className={`hamburger-menu-container ${customClass}`}>
      <ul>
        {isLiveGame &&
          <NavItem icon={isLiveNavigation[0].icon} value={isLiveNavigation[0].title} customClass={isLiveNavigation[0].customClass} link={isLiveNavigation[0].link} />
        }
        {mainNavigation && mainNavigation.map((item) => (
          <NavItem key={item._id} icon={item.icon} value={item.title} customClass={item.customClass} link={item.link} />
        ))}
      </ul>
    </div>
  );
}

export default HamburgerMenu;