const SET_TOGGLE = 'teams/SET_TOGGLE';
const SET_DATA = 'teams/SET_DATA';
const SET_SELECT_OPTIONS = 'teams/SET_SELECT_OPTIONS';
const SET_SELECT_INDEX = 'teams/SET_SELECT_INDEX';
const SET_TEAM_DATA = 'teams/SET_TEAM_DATA';
const SET_SCHEDULE_DATA = 'teams/SET_SCHEDULE_DATA';

export const setToggle = (event: boolean) => ({ type: SET_TOGGLE, payload: event });
export const setData = (event: any ) => ({ type: SET_DATA, payload: event });
export const setSelectOptions = (event: any) => ({ type: SET_SELECT_OPTIONS, payload: event });
export const setSelectIndex = (event: number) => ({ type: SET_SELECT_INDEX, payload: event });
export const setTeamData = (event: any) => ({ type: SET_TEAM_DATA, payload: event });
export const setScheduleData = (event: any) => ({ type: SET_SCHEDULE_DATA, payload: event });

type Action = {
  type: string;
  payload?: any;
};

export type teamDataProps = {
  _id: string;
  index: number;
  guid: string;
  name: string;
  image: string;
  link: string;
}

export type InitialState = {
  toggle: string;
  toggleCheck: boolean;
  league: string;
  selectIndex: number;
  selectOptions: any[];
  teamData: teamDataProps[];
  teamSiteName: string;
  link: string;
  team: any;
  schedule: any;
};

const INITIAL_STATE = {
  toggle: 'man',
  toggleCheck: false,
  league: '',
  selectIndex: 0,
  selectOptions: [],
  teamData: [],
  teamSiteName: '',
  link: '',
  team: [],
  schedule: []
};

function saveSessionStorageToggle(state: boolean) {
  const isBool = state !== undefined ? state : false;
  sessionStorage.setItem('teamStateToggle', JSON.stringify(isBool));
}

export function loadTeamStateToggle(): boolean {
  const state = sessionStorage.getItem('teamStateToggle');
  return state ? JSON.parse(state) : false;
}


function reducer(state: InitialState = INITIAL_STATE, action: Action) {
  let newState;
  switch (action.type) {
  case SET_TOGGLE:
    newState = { ...state, toggle: toggleAction(action.payload), toggleCheck: action.payload, selectIndex: 0 };
    saveSessionStorageToggle(action.payload);
    return newState;
  case SET_DATA:
    return { ...state, teamData: action.payload.teams, league: action.payload.league };
  case SET_SELECT_OPTIONS:
    return { ...state, selectOptions: action.payload };
  case SET_SELECT_INDEX:
    return { ...state, selectIndex: action.payload };
  case SET_TEAM_DATA:
    return { ...state, team: action.payload, teamSiteName: action.payload.team.name };
  case SET_SCHEDULE_DATA:
    return { ...state, schedule: action.payload };
  default:
    return state;
  }
}

function toggleAction(state: boolean) {
  return state? 'woman' : 'man';
}

export default reducer;
