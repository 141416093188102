import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface RouteWithTitleProps {
  title: string;
  children: React.ReactNode;
}

function RouteWithTitle({ title, children } : RouteWithTitleProps) {
  const location = useLocation();

  useEffect(() => {
    document.title = title;
  }, [location, title]);

  return (
    <>
      { children }
    </>
  );
}

export default RouteWithTitle;