import React, { useState, useEffect } from "react";

interface TableItemProps {
  position: number;
  teamLogo: string;
  teamName: string;
  games: string;
  wins: number;
  draw: number;
  loose: number;
  goals: string;
  goalsDiff: number;
  points: number;
  isActive?: boolean;
}

function TableItem({ position, teamLogo, teamName, games, wins, draw, loose, goals, goalsDiff, points, isActive }: TableItemProps) {
  const [active, setActive] = useState('');

  useEffect(() => {
    if(isActive) {
      setActive('active');
    }
  }, [isActive]);

  return (
    <div className={`table-item-container ${active}`}>
      <div className="table-item_position">{position}</div>
      <div className="table-item_team">
        <img src={teamLogo} alt={`logo ${teamName}`} width={32} height={32} />
        <span>{teamName}</span></div>
      <div className="table-item_games">{games}</div>
      <div className="table-item_wins">{wins}</div>
      <div className="table-item_draw">{draw}</div>
      <div className="table-item_loose">{loose}</div>
      <div className="table-item_goals">{goals}</div>
      <div className="table-item_goalsDiff">{goalsDiff}</div>
      <div className="table-item_points">{points}</div>
    </div>
  );
}

export default TableItem;