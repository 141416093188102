import React from "react";
import { useTranslation } from "react-i18next";

import Team from "../panelCardsComponents/team/Team";
import Scores from "../panelCardsComponents/scores/Scores";
import LiveButtons from "../panelCardsComponents/liveButtons/LiveButtons";

import UseFormatTime from "../../hooks/useFormatTime";

import "./LivePanel.scss";

export interface Result {
  home: number;
  guest: number;
}

export interface TeamData {
  home: string;
  guest: string;
  picture: string;
}

export interface GameData {
  _id: string;
  index: number;
  guid: string;
  time: number;
  formatTime: string;
  results: Result[];
  stream: string;
  ticker: string;
  teams: TeamData[];
  status: string;
}

interface LivePanelProps {
  data: GameData[];
}

function LivePanel({ data }: LivePanelProps) {
  const { t } = useTranslation();

  return (
    <div className="live-panel-container">
      {data && data.map((item: GameData) => (
        <div key={item._id} className="live-panel-item live-games">
          <div className="live-gametime">
            {item.time ?
              <>
                {UseFormatTime(item.time)} {t('live-panel.clock')}
              </>
              :
              <></>
            }
          </div>
          <Team
            logoHome={item.teams[0].picture}
            teamHome={item.teams[0].home}
            logoGuest={item.teams[1].picture}
            teamGuest={item.teams[1].guest}
          />
          <Scores scoreData={item.results} status={item.status} />
          <LiveButtons tickerLinker={item.ticker} streamLink={item.stream} />
        </div>
      ))}
    </div>
  );
}

export default LivePanel;