import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import CadreTableItem from "./CadreTableItem/CadreTableItem";
import CadreTableHead from "./CadreTableItem/CadreTableHead";
import CadreTeamInfo from "./CadreTableItem/CadreTeamInfo";

import { CadreItemProps, LiveTickerCadre } from './CadreTableItem/Cadre.types';

function CadreItem({ cadre, captain, coach, tutor, teamSupport, wip, customClass }: CadreItemProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className={`cadre-table-wrapper ${customClass}`}>
        <CadreTableHead teamCadre={false} />
        {cadre && cadre.map((item: LiveTickerCadre ) => (
          <CadreTableItem
            key={uuidv4()}
            teamCadre={false}
            jerseyNumber={item.jersayNumber}
            player={item.fullname}
            nation={item.nationalityCode}
            birthday={item.birthday}
            goals={item.goals}
            errors={item.errors}
            ame={item.ame}
          />
        ))}
      </div>

      <CadreTeamInfo captain={captain} tutor={tutor} coach={coach} teamSupport={teamSupport} customClass={customClass} />

      {wip && (
        <div className={`cadre-team-info-container ${customClass}`}>
          <div className="cadre-team-info">
            <span>{t('live-panel.wip')}: </span>
            {wip}
          </div>
        </div>
      )}
    </>
  );
}

export default CadreItem;