import React from "react";
import { useTranslation } from 'react-i18next';

import "./GameDate.scss";
import useFormatDays from "../../../hooks/useFormatDays";
import useFormatTime from "../../../hooks/useFormatTime";

interface GameDateProps {
  date: string;
  time: string;
  location: string;
}

function GameDate({ date, time, location }: GameDateProps) {
  const { t } = useTranslation();
  const dateValue = useFormatDays(parseInt(date));
  const timeValue = useFormatTime(parseInt(time));

  return (
    <div className="game-date-container">
      <div className="game-date">
        <span className="game-date_day">{dateValue}</span>
        <span>,</span>
        <span className="game-date_time">{timeValue} {t('live-panel.clock')}</span>
      </div>
      <span className="game-date_location">{location}</span>
    </div>
  );
}

export default GameDate;