import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";

import { changeSelectOption } from '../../store/scheduleStore';

import { RootState } from "../../store/store";
import { customStyles } from './style';
import './CustomSelect.scss';

interface Option {
  category: number;
  value: string;
  label: string;
  index: number;
}

interface CustomScheduleSelectProps {
  customClass?: string;
}

function CustomScheduleSelect({ customClass = '' }: CustomScheduleSelectProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectOptions } = useSelector((state: RootState) => state.schedule);
  const [selectedOption, setSelectedOption] = useState<Option | null>(selectOptions[0] || null);


  useEffect(() => {
    if(selectOptions.length === 0) {
      return;
    }

    setSelectedOption(selectOptions[0]);
  }, [selectOptions]);

  function changeOptions(value: SingleValue<Option>) {
    if (value) {
      const index = selectOptions.findIndex((option: Option) => {
        return option.value === value.value
      });
      const optionValue = value.value;
      setSelectedOption(selectOptions[index] || null);
      dispatch(changeSelectOption({ value: optionValue, index: index }));
    } else {
      setSelectedOption(null);
    }
  }

  return (
    <div className={`custom-select-container ${customClass}`}>
      <Select
        options={selectOptions}
        styles={customStyles}
        value={selectedOption}
        onChange={(value) => changeOptions(value)}
        placeholder={t('filter')}
        isSearchable={false}
        aria-label={t('filter-label')}
      />
    </div>
  );
}

export default CustomScheduleSelect;