import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface LiveTickerItemProps {
  quarter: string;
  time: string;
  isHomeTeam?: boolean;
  isGuestTeam?: boolean;
  player: string;
  event: string;
  eventLogo: string;
  score?: string;
}

function LiveTickerItem({ quarter, time, isHomeTeam, isGuestTeam, player, event, eventLogo, score }:LiveTickerItemProps) {
  const [homeTeam, setHomeTeam] = useState('');
  const [guestTeam, setGuestTeam] = useState('');
  const [quarterText, setQuarterText] = useState('');
  const [eventText, setEventText] = useState('');
  const [timeText, setTimeText] = useState('');

  const { t } = useTranslation();

  useEffect(() => {

    if(isHomeTeam) {
      setHomeTeam('event-container home-team');
    } else {
      setHomeTeam('event-container');
    }

    if(isGuestTeam) {
      setGuestTeam('event-container guest-team');
    } else {
      setGuestTeam('event-container');
    }

  }, [isHomeTeam, isGuestTeam]);

  useEffect(() => {
    switch (quarter) {
    case "8":
      setQuarterText('5m');
      setTimeText('');
      break;
    default:
      setQuarterText(quarter);
      setTimeText(time);
      break;
    }

    switch (event) {
    case "KT":
      setEventText(t('live-panel.KT'));
      break;
    default:
      setEventText(event);
      break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="live-ticker-item-container">

      <div className="quarter">{quarterText}</div>
      <div className="time">{timeText}</div>

      <div className={homeTeam}>
        {isHomeTeam &&
          <>
            <div className="player">{player}</div>
            <div className="event">{eventText}</div>
          </>
        }
      </div>

      <div className="event-logo">
        <img src={`${process.env.PUBLIC_URL}${eventLogo}`} alt="event Logo" width={48} height={48}/>
      </div>

      <div className={guestTeam}>
        {isGuestTeam &&
          <>
            <div className="player">{player}</div>
            <div className="event">{eventText}</div>
          </>
        }
      </div>

      {/*<div className={`${guestTeam} show-mobile`}>*/}
      {/*  {isHomeTeam &&*/}
      {/*    <>*/}
      {/*      <div className="player">{player}</div>*/}
      {/*      <div className="event">{event}</div>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*</div>*/}

      <div className="score">{score}</div>

    </div>
  );
}

export default LiveTickerItem;