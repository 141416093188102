import React from "react";
import { useTranslation } from "react-i18next";

import useFormatDays from "../../hooks/useFormatDays";
import YouTubeEmbed from "../../components/YouTubeEmbed/YouTubeEmbed";

interface NewsVideoProps {
  type: string;
  date?: any;
  title: string;
  image?: string;
  intro: string;
  description: string;
  video?: string;
  youtube?: string;
  image_copyright_text?: string;
}


function NewsVideoLayout({ type, date = '', title, image, intro, description, video = "", youtube = "", image_copyright_text = "" }: NewsVideoProps) {
  const { t } = useTranslation();
  const formattedDate = useFormatDays(date);

  return (
    <>
      <div className="news-type icon">{t(`news.${type}`)}</div>
      {date ?
        <div className="news-date">{formattedDate}</div>
        :
        <div className="news-date"></div>
      }

      {image && (
        <div className="news-image">
          <img src={image} alt={title}/>
          {image_copyright_text && (
            <figcaption>{image_copyright_text}</figcaption>
          )}
        </div>
      )}

      <h1>{title}</h1>

      {description && (
        <div className="news-description">
          <div className="news-intro" dangerouslySetInnerHTML={{ __html: intro }}/>
          <div dangerouslySetInnerHTML={{ __html: description }}/>
        </div>
      )}
      {video && (
        <video className="video" controls>
          <source src={video} type="video/mp4"/>
          Your browser does not support the video element.
        </video>
      )}

      {youtube && (
        <div className="video">
          <YouTubeEmbed videoId={youtube} title="YoutubeVideo" />
        </div>
      )}
    </>
  );
}

export default NewsVideoLayout;