import React from "react";
import { useTranslation } from 'react-i18next';

import "./LiveText.scss";

interface LiveTextProps {
  customClass?: string;
}

function LiveText({ customClass = "" }: LiveTextProps) {
  const { t } = useTranslation();

  return (
    <div className={`live-text-container ${customClass}`}>
      <span>{t('live-panel.live')}</span>
    </div>
  );
}

export default LiveText;