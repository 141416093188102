import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useTranslation } from 'react-i18next';

import NewsItem from "./NewsItem";
import Button from "../buttons/Button";
import Lightbox from "../lightBox/Lightbox";
import NewsLightBox from "../lightBox/newsLightBox/NewsLightBox";

import { RootState } from "../../store/store";

interface NewsMasonryProps {
  newsData: any;
  totalNewsCount?: number;
  isMoreLoading?: boolean;
  onClick: () => void;
}

interface NewsItemData {
  _id: string;
  customClass?: string;
  date: string;
  type: string;
  title: string;
  intro: string
  description: string;
  image: string;
  newsTeam: string;
  image_copyright_text: string;
}

function NewsMasonry({ newsData, totalNewsCount = 0, isMoreLoading = false, onClick }:NewsMasonryProps) {
  const { isLightBoxOpen, newsRoomId } = useSelector((state: RootState) => state.config);

  const [newsSingleData, setNewsSingleData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (newsData === null || newsData.length === 0) {
      return;
    }

    const filteredNewsData = newsData.filter((item: NewsItemData) => String(item._id) === String(newsRoomId));
    setNewsSingleData(filteredNewsData);
  }, [newsData, newsRoomId]);

  return (
    <>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 568: 2, 900: 3, 1280: 4 }}
      >
        {newsData ? (
          <Masonry gutter="1.25rem" >
            {newsData.map((item: NewsItemData) => (
              <NewsItem
                key={uuidv4()}
                id={item._id}
                customClass={item.customClass}
                date={item.date}
                type={item.type}
                title={item.title}
                intro={item.intro}
                image={item.image}
                newsTeam={item.newsTeam}
                copyRight={item.image_copyright_text}
              />
            ))}
          </Masonry>
        ) : (
          <></>
        )}
      </ResponsiveMasonry>

      {newsData || !isMoreLoading ?
        <div className="button-container">
          {totalNewsCount <= newsData.length ?
            <></> : <Button label={t("load-more")} onClick={onClick}/>
          }
        </div>
        : <></>
      }

      {newsData && isMoreLoading ?
        <div className="button-container">
          <Button label={t("load-more")} onClick={onClick}/>
        </div>
        : <></>
      }

      {newsSingleData.length > 0 &&
        <>
          {isLightBoxOpen &&
            <Lightbox>
              <NewsLightBox data={newsSingleData[0]}/>
            </Lightbox>
          }
        </>
      }
    </>
  );
}

export default NewsMasonry;