import React from "react";
import { useTranslation } from "react-i18next";
import GameStatisticItem from "./GameSatisticItem";
import TeamItem from "../liveTicker/MatchTeams/TeamItem";

import "./GameStatistic.scss";

interface TeamDataProps {
  home: string;
  guest: string;
  picture: string;
  customClass: string;
  displayLogos: boolean;
}

interface GameStatisticItemProps {
  _id: string;
  title: string;
  home: number;
  guest: number;
  percentHome: number;
  percentGuest: number;
  percent: boolean;
}


interface GameStatisticProps {
  teamData: TeamDataProps[];
  statisticData: GameStatisticItemProps[];
}

function GameStatistic({ teamData, statisticData }: GameStatisticProps) {
  const { t } = useTranslation();

  return (
    <div className="module_game-statistic">
      <h2>{t('statistic.statistic')}</h2>

      <div className="statistic-teams">
        <TeamItem teamName={teamData[0].home} teamLogo={teamData[0].picture} customClass="team-home" displayLogos={false} />
        <TeamItem teamName={teamData[1].guest} teamLogo={teamData[1].picture} customClass="team-guest" displayLogos={false} />
      </div>

      <div className="game-statistic-container">
        {statisticData && statisticData.map((item) => (
          <GameStatisticItem
            key={item._id}
            title={item.title}
            home={item.home}
            guest={item.guest}
            percentHome={item.percentHome}
            percentGuest={item.percentGuest}
            percent={item.percent} />
        ))}
      </div>
    </div>
  );
}

export default GameStatistic;
