import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from 'react-i18next';

import GameTable from "../../components/gameTable/GameTable";
import DatePanelHead from "../../components/panelCardsComponents/datePanelHead/DatePanelHead";
import GameButtons from "../../components/panelCardsComponents/gameButtons/GameButtons";
import Table from "../../components/panelCardsComponents/table/Table";
import ScorerTable from "../../components/panelCardsComponents/table/ScorerTable/ScorerTable";
import Anker from "../../components/Anker/Anker";

import "../../components/livePanel/LivePanel.scss";
import Schedule from "../../components/placeholder/Schedule";

type ActiveSection = 'schedule' | 'table' | 'scorer' | 'nomination';

interface GamesResultLayoutProps {
  anker?: string;
}

function GamesResultLayout({ anker }: GamesResultLayoutProps ) {
  const { t } = useTranslation();
  const { schedule, table, scorer, mvp, selectedWeek } = useSelector((state: RootState) => state.schedule);
  const [isActive, setIsActive] = useState<ActiveSection>('schedule');
  const [daySchedule, setDaySchedule] = useState<any>([]);

  const [scorerData, setScorerData] = useState<any>([]);
  const [scorerPagination, setScorerPagination] = useState<number>(1);
  const [scorerIsPagination, setScorerIsPagination] = useState({ prev: false, next: true } );
  const [scorerLength, setScorerLength] = useState<number>(0);

  const [mvpData, setMvpData] = useState<any>([]);
  const [nominationPagination, setNominationPagination] = useState<number>(1);
  const [mpvIsPagination, setMvpIsPagination] = useState({ prev: false, next: true } );
  const [mvpLength, setMvpLength] = useState<number>(0);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (Object.keys(schedule).length === 0 || selectedWeek === 0) {
      return;
    }
    setIsLoaded(false);
    const randomDelay = Math.floor(Math.random() * 100) + 150;

    const week = selectedWeek.toString();
    setDaySchedule(schedule[week]);

    setTimeout(() => {
      setIsLoaded(true);
    }, randomDelay);

  }, [schedule, selectedWeek]);

  useEffect(() => {
    if (scorer.length === 0) {
      return;
    }

    setScorerLength(scorer.length);
    setScorerData(scorer.slice(0, 10));
  }, [scorer]);

  useEffect(() => {
    if (mvp.length === 0) {
      return;
    }
    setMvpLength(mvp.length);
    setMvpData(mvp.slice(0, 10));
  }, [mvp]);

  const changeSection = (event: ActiveSection) => {
    setIsActive(event);
  };

  const nextScorerPagination = () => {
    const startIndex = scorerPagination * 10;
    const endIndex = startIndex + 10;

    if (endIndex >= mvp.length) {
      setScorerIsPagination({ prev: true, next: false });
    } else {
      setScorerIsPagination({ prev: true, next: true });
    }

    setScorerPagination(scorerPagination + 1);
    setScorerData(scorer.slice(startIndex, endIndex));
  };

  const prevScorerPagination = () => {
    if (scorerPagination === 1) {
      setScorerIsPagination({ prev: false, next: true });
      return;
    }

    const newPagination = scorerPagination - 1;
    const startIndex = (newPagination - 1) * 10;
    const endIndex = startIndex + 10;

    setScorerPagination(newPagination);
    setScorerData(scorer.slice(startIndex, endIndex));

    setScorerIsPagination({ prev: newPagination > 1, next: true });
  };

  const nextMvpPagination = () => {
    const startIndex = nominationPagination * 10;
    const endIndex = startIndex + 10;

    if (endIndex >= mvp.length) {
      setMvpIsPagination({ prev: true, next: false });
    } else {
      setMvpIsPagination({ prev: true, next: true });
    }

    setNominationPagination(nominationPagination + 1);
    setMvpData(mvp.slice(startIndex, endIndex));
  };

  const prevMvpPagination = () => {
    if (nominationPagination === 1) {
      setMvpIsPagination({ prev: false, next: true });
      return;
    }

    const newPagination = nominationPagination - 1;
    const startIndex = (newPagination - 1) * 10;
    const endIndex = startIndex + 10;

    setNominationPagination(newPagination);
    setMvpData(mvp.slice(startIndex, endIndex));

    setMvpIsPagination({ prev: newPagination > 1, next: true });
  };


  return (
    <section className="module_gamesResult-layout">
      <Anker anker={anker} />
      <div className="container">
        <h2>{t('title.gameResult')}</h2>

        <GameButtons
          scheduleButton={daySchedule && daySchedule.length > 0 ? [() => changeSection('schedule'), 'schedule'] : undefined}
          tableButton={table && table.length > 0 ? [() => changeSection('table'), 'table'] : undefined}
          scorerButton={scorer && scorer.length > 0 ? [() => changeSection('scorer'), 'scorer'] : undefined}
          nominationButton={mvp && mvp.length > 0 ? [() => changeSection('nomination'), 'nomination'] : undefined}
          activeSection={isActive}
        />

        {isActive === 'schedule' && daySchedule && daySchedule.length > 0 ?
          <>
            <DatePanelHead/>
            {isLoaded ? <GameTable data={ daySchedule } /> : <Schedule/>}
          </>
          : <></>
        }

        {isActive === 'table' && table && table.length > 0  ?
          <>
            <Table data={ table } />
          </>
          : <></>
        }

        {isActive === 'scorer' && scorer && scorer.length > 0 ?
          <>
            <ScorerTable isScorer={true} isNomination={false} data={scorerData}/>

            {scorerLength > 10 ?
              <div className="pagination-container">
                <button className="pagination prev" aria-label={t('prev-aria-label')} disabled={!scorerIsPagination.prev}
                  onClick={() => prevScorerPagination()}></button>

                <button className="pagination next" aria-label={t('next-aria-label')} disabled={!scorerIsPagination.next}
                  onClick={() => nextScorerPagination()}></button>
              </div>
              : <></>
            }
          </>
          : <></>
        }

        {isActive === 'nomination' && mvp && mvp.length > 0 ?
          <>
            <ScorerTable isScorer={false} isNomination={true} data={mvpData}/>

            {mvpLength > 10 ?
              <div className="pagination-container">
                <button className="pagination prev" aria-label={t('prev-aria-label')} disabled={!mpvIsPagination.prev}
                  onClick={() => prevMvpPagination()}></button>

                <button className="pagination next" aria-label={t('next-aria-label')} disabled={!mpvIsPagination.next}
                  onClick={() => nextMvpPagination()}></button>
              </div>
              : <></>
            }
          </>
          : <></>
        }
      </div>
    </section>
  );
}

export default GamesResultLayout;