import { ThunkAction } from 'redux-thunk';
import { RootState } from './store';
import { Action as ReduxAction } from 'redux';

const LOAD_DATA = 'data/LOAD_DATA';

const location =  window.location.hostname;
let baseUrl = '';

switch (location) {
case 'localhost':
  baseUrl = 'http://localhost:3000/testApi';
  break;
case 'wasserball-bundesliga.de':
  baseUrl = 'https://content.wasserball-bundesliga.de';
  break;
case 'waba.codersunlimited.com':
  baseUrl = 'https://content-waba.codersunlimited.com';
  break;
default:
  baseUrl = 'https://content.wasserball-bundesliga.de';
}

const urlAllTeams = `${baseUrl}/storage/content/allTeams.json`;
const urlAllNews = `${baseUrl}/storage/content/news/1.json`;

interface LoadDataAction extends ReduxAction<typeof LOAD_DATA> {
  payload: any;
  dataType: 'teams' | 'news';
}

const loadData = (url: string, dataType: 'teams' | 'news' ): ThunkAction<void, RootState, unknown, LoadDataAction> => async dispatch => {
  try {
    const response = await fetch(url, { method: 'GET' });
    const data = await response.json();
    dispatch({ type: LOAD_DATA, payload: data, dataType });
  } catch (error) {
    console.error(`Failed to load ${dataType} data`, error);
  }
};

export const loadTeamsData = () => loadData(urlAllTeams, 'teams');
export const loadNewsData = () => loadData(urlAllNews, 'news');

type Action = LoadDataAction;

export type InitialState = {
  allTeamsData: any;
  allTeamsOptions: any;
  allTeamLinks: any;
  allNewsData: any;
  allLiveStreamData: any;
  gameTableData: any;
  tableData: any;
  scheduleData: any;
  scorerData: any;
};

const INITIAL_STATE = {
  allTeamsData: [],
  allTeamsOptions: [],
  allTeamLinks: [],
  allNewsData: [],
  allLiveStreamData: [],
  gameTableData: [],
  tableData: [],
  scheduleData: [],
  scorerData: []
}; 

function reducer(state: InitialState = INITIAL_STATE, action: Action) {
  switch (action.type) {
  case LOAD_DATA:
    if (action.dataType === 'teams') {
      return { ...state, allTeamsData: action.payload, allTeamLinks: createLinkPath(action.payload), allTeamsOptions: createOptions(action.payload) };
    } else if (action.dataType === 'news') {
      return { ...state, allNewsData: action.payload };
    }
    return state;
  default:
    return state;
  }
}

function createLinkPath(data: any) {
  const flattenedData = [
    ...data.man.flatMap((league: any) => league.data),
    ...data.woman.flatMap((league: any) => league.data)
  ];

  return flattenedData.map((item: any) => {
    return {
      pathOrder: item.uid,
      pathLink: item.link.replace('/team/', '').toLowerCase(),
    };
  });
}

function createOptions(data: any) {
  const manOptions = data.man.map((item: any, index: number) => {
    return {
      value: item._id,
      label: item.title,
      index: index
    };
  });

  const womanOptions = data.woman.map((item: any, index: number) => {
    return {
      value: item._id,
      label: item.title,
      index: index
    };
  });

  return {
    man: manOptions,
    woman: womanOptions
  };
}

export default reducer;