import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

const useBodyScrollLock = () => {
  const { isLightBoxOpen } = useSelector((state: RootState) => state.config);

  useEffect(() => {
    if (isLightBoxOpen) {
      document.body.classList.add('body-no-scroll');
    } else {
      document.body.classList.remove('body-no-scroll');
    }

    return () => {
      document.body.classList.remove('body-no-scroll');
    };
  }, [isLightBoxOpen]);
};

export default useBodyScrollLock;