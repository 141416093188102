import React from "react";
import { useTranslation } from "react-i18next";

function TableHead() {
  const { t } = useTranslation();

  return (
    <div className="table-item-container table-head">
      <div className="table-item_position">{t('table.position')}</div>
      <div className="table-item_team">{t('table.team')}</div>
      <div className="table-item_games">{t('table.games')}</div>
      <div className="table-item_wins">{t('table.win')}</div>
      <div className="table-item_draw">{t('table.draw')}</div>
      <div className="table-item_loose">{t('table.lose')}</div>
      <div className="table-item_goals">{t('table.goal')}</div>
      <div className="table-item_goalsDiff">{t('table.goal-difference')}</div>
      <div className="table-item_points">{t('table.points')}</div>
    </div>
  );
}

export default TableHead;