const INIT_TEAM_PAGINATION = 'pagination/INIT_TEAM_PAGINATION';
const TEAM_PAGINATION = 'pagination/TEAM_PAGINATION';
const RESET_TEAM_PAGINATION = 'pagination/RESET_TEAM_PAGINATION';
const INIT_LIVE_PAGINATION = 'pagination/INIT_LIVE_PAGINATION';
const LIVE_PAGINATION = 'pagination/LIVE_PAGINATION';

export const initTeamPagination = (event: number) => ({ type: INIT_TEAM_PAGINATION, payload: event });
export const teamPagination = (valueType: string) => ({ type: TEAM_PAGINATION, payload: { valueType } });
export const resetTeamPagination = () => ({ type: RESET_TEAM_PAGINATION });
export const initLivePagination = (event: number) => ({ type: INIT_LIVE_PAGINATION, payload: event });
export const livePagination = (valueType: string) => ({ type: LIVE_PAGINATION, payload: { valueType } });

type Action = {
  type: string;
  payload?: any;
};

export type PaginationValue = {
  now: number;
  count: number;
  maxData: number;
  showPagination: boolean;
  prevDisabled: boolean;
  nextDisabled: boolean;
};

export type InitialState = {
  teamPaginationValue: PaginationValue;
  livePanelValue: PaginationValue;
};

const INITIAL_STATE = {
  teamPaginationValue: {
    now: 0,
    count: 12,
    maxData: 0,
    showPagination: false,
    prevDisabled: true,
    nextDisabled: false,
  },
  livePanelValue: {
    now: 0,
    count: 2,
    maxData: 0,
    showPagination: false,
    prevDisabled: true,
    nextDisabled: false,
  }
};

function paginationAction(type: string, now: number, maxData: number, count: number) {
  const newNow = type === 'increment' ? now + 1 : now - 1;
  return {
    now: newNow,
    prevDisabled: newNow <= 0,
    nextDisabled: newNow >= Math.ceil(maxData / count) - 1
  };
}

function reducer(state: InitialState = INITIAL_STATE, action: Action) {
  switch (action.type) {
  case INIT_TEAM_PAGINATION:
    return {
      ...state,
      teamPaginationValue: {
        ...state.teamPaginationValue,
        maxData: action.payload,
        showPagination: show(state.teamPaginationValue.count, action.payload)
      }
    };
  case TEAM_PAGINATION:
    return {
      ...state,
      teamPaginationValue: {
        ...state.teamPaginationValue,
        ...paginationAction(action.payload.valueType, state.teamPaginationValue.now, state.teamPaginationValue.maxData, state.teamPaginationValue.count)
      }
    };
  case RESET_TEAM_PAGINATION:
    return {
      ...state,
      teamPaginationValue: {
        ...state.teamPaginationValue,
        now: 0,
        prevDisabled: true,
        nextDisabled: false
      }
    };
  case INIT_LIVE_PAGINATION:
    return {
      ...state,
      livePanelValue: {
        ...state.livePanelValue,
        maxData: action.payload,
        showPagination: show(state.livePanelValue.count, action.payload)
      }
    };
  case LIVE_PAGINATION:
    return {
      ...state,
      livePanelValue: {
        ...state.livePanelValue,
        ...paginationAction(action.payload.valueType, state.livePanelValue.now, state.livePanelValue.maxData, state.livePanelValue.count)
      }
    };
  default:
    return state;
  }
}

function show(count: number, max: number) {
  return count < max;
}


export default reducer;