const CHANGE_FILTER = 'newsroom/CHANGE_FILTER';
const ADD_NEWS = 'newsroom/ADD_NEWS';
const RESET_NEWS = 'newsroom/RESET_NEWS';
const NEXT_NEWS_DATA = 'newsroom/NEXT_NEWS_DATA';
const ADD_NEXT_NEWS = 'newsroom/ADD_NEXT_NEWS';

export const changeFilter = (event: any) => ({ type: CHANGE_FILTER, payload: event });
export const addNews = (event: any) => ({ type: ADD_NEWS, payload: event });
export const resetNews = () => ({ type: RESET_NEWS });
export const nextNewsData = (event: string) => ({ type: NEXT_NEWS_DATA, payload: event });
export const addNextNews = (event: any) => ({ type: ADD_NEXT_NEWS, payload: event });

type Action = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

type News = {
  _id: string | number;
  index: number;
  guid: string;
  type: string;
  customClass: string;
  date: number;
  title: string;
  description: string;
  image: string;
  link: string;
  newsTeam: boolean;
};

export type InitialState = {
  sortedOptions: { value: string; label: string, index: number }[];
  sortedValue: string;
  sortedIndex: number;
  isSelected: boolean;
  allNewsData: News[];
  newNewsData: string | null;
  newsData: News[];
};

const INITIAL_STATE = {
  sortedOptions: [
    { value: 'article', label: 'Nach Artikel', index: 0 },
    { value: 'podcast', label: 'Nach Podcast', index: 1 },
    { value: 'whitepaper', label: 'Nach Whitepaper', index: 2 },
    { value: 'video', label: 'Nach Video', index: 3 },
  ],
  sortedValue: '',
  sortedIndex: 0,
  isSelected: false,
  allNewsData: [],
  newNewsData: null,
  newsData: []
};

// function saveSessionStorageNews(state: { index: number, value: string, isSelected: boolean }) {
//   sessionStorage.setItem('newsState', JSON.stringify(state));
// }

export function loadNewsStateIndex(): { index: number, value: string, isSelected: boolean } | null {
  const state = sessionStorage.getItem('newsState');
  return state ? JSON.parse(state) : null;
}

function reducer(state: InitialState = INITIAL_STATE, action: Action) {
  let newState;
  switch (action.type) {
  case CHANGE_FILTER:
    newState = {
      ...state,
      newsData: filterNews([...state.allNewsData], action.payload.value),
      sortedValue: action.payload.value,
      sortedIndex: 2,
      isSelected: true
    };
    return newState;
  case ADD_NEWS:
    const savedState = loadNewsStateIndex();
    return {
      ...state,
      allNewsData: [...action.payload],
      sortedValue: savedState ? savedState.value : '',
      sortedIndex: savedState ? 2 : 0,
      newsData: filterNews([...action.payload], state.sortedValue)
    };
  case NEXT_NEWS_DATA:
    return {
      ...state,
      newNewsData: action.payload
    };
  case ADD_NEXT_NEWS:
    return {
      ...state,
      allNewsData: [...state.allNewsData, ...action.payload],
      newsData: filterNews([...state.allNewsData, ...action.payload], state.sortedValue)
    };
  case RESET_NEWS:
    sessionStorage.removeItem('newsState');
    return {
      ...state,
      sortedValue: 'null',
      isSelected: false,
      newsData: filterNews([...state.allNewsData], '')
    };
  default:
    return state;
  }
}

function filterNews(news: News[], filter: string) {
  if (filter === '') {
    return news.sort((a, b) => b.date - a.date);
  }
  const filteredNews = news.filter(newsItem => newsItem.type === filter);
  const sortedNews = filteredNews.sort((a, b) => b.date - a.date);
  return sortedNews.length === 0 ? [] : sortedNews;
}

export default reducer;