const OPEN_LIGHTBOX = 'config/OPEN_LIGHTBOX';
const NEWS_ROOM_ID = 'config/NEWS_ROOM_ID';
const IS_LOGIN_COOKIE = 'config/IS_LOGIN_COOKIE';
const IS_LIVE_GAME_SET = 'config/IS_LIVE_GAME_SET';
const LIVE_TICKER_SITE = 'config/LIVE_TICKER_SITE';

export const openLightBox = (event: boolean) => ({ type: OPEN_LIGHTBOX, payload: event });
export const newsRoomId = (event: string | number ) => ({ type: NEWS_ROOM_ID, payload: event });
export const isLoginCookie = (event: boolean) => ({ type: IS_LOGIN_COOKIE, payload: event });
export const isLiveGameSet = (event: boolean) => ({ type: IS_LIVE_GAME_SET, payload: event });
export const setLiveTickerSite = (event: string | null) => ({ type: LIVE_TICKER_SITE, payload: event });

const isLocalhost = window.location.hostname === 'localhost';
const location =  window.location.hostname;
let baseUrl = '';

switch (location) {
case 'localhost':
  baseUrl = 'http://localhost:3000/testApi';
  break;
case 'wasserball-bundesliga.de':
  baseUrl = 'https://content.wasserball-bundesliga.de';
  break;
case 'waba.codersunlimited.com':
  baseUrl = 'https://content-waba.codersunlimited.com';
  break;
default:
  baseUrl = 'https://content.wasserball-bundesliga.de';
}


type Action = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export type InitialState = {
  baseUrl: string;
  isLocal: boolean;
  isLiveGame: boolean
  mainInformation: {
    address: string;
    email: string;
    emailText: string;
    phone: string;
    phoneText: string;
  };
  dsvInformation: {
    logo: string;
    alt: string;
    link: string;
    address: string;
  };
  copyRightCompany: string;
  isLiveStream: boolean;
  liveTimeInterval: number;
  liveTickerInterval: number;
  liveTableCount: number;
  newsCount: number;
  liveStreamCount: number;
  popupWidth: number;
  popupHeight: number;
  isLightBoxOpen: boolean;
  newsRoomId: number | string | null;
  isLoginCookie: boolean;
  liveTickerSite: string | null;
};


const INITIAL_STATE = {
  baseUrl: baseUrl,
  isLocal: isLocalhost,
  isLiveGame: false,
  mainInformation: {
    address: '<p>Lorem Ipsum<br />Max Mustermann<br />14053 Berlin</p>',
    email: 'mail@musterkontakt.de',
    emailText: 'contact',
    phone: '0049123456789',
    phoneText: 'phone-text'
  },
  dsvInformation: {
    logo: '../assets/logos/log-dsv@2x.webp',
    alt: 'DSV Logo',
    link: 'https://www.dsv.de',
    address: '<p>Deutscher Schwimmverband e.V.<br />Korbacher Straße 93<br />D-34132 Kassel</p>'
  },
  copyRightCompany: 'Deutscher Schwimmverband &shy; // Abteilung&nbsp;Wettkampfsport&nbsp;Wasserball',
  isLiveStream: true,
  liveTimeInterval: 30000,
  liveTickerInterval: 30000,
  liveTableCount: 2,
  newsCount: 8,
  liveStreamCount: 4,
  popupWidth: 700,
  popupHeight: 600,
  isLightBoxOpen: false,
  newsRoomId: null,
  isLoginCookie: true,
  liveTickerSite: null
};


function reducer(state: InitialState = INITIAL_STATE, action: Action) {
  switch (action.type) {
  case OPEN_LIGHTBOX:
    return { ...state, isLightBoxOpen: action.payload };
  case IS_LIVE_GAME_SET:
    return { ...state, isLiveGame: action.payload };
  case NEWS_ROOM_ID:
    return { ...state, newsRoomId: action.payload };
  case IS_LOGIN_COOKIE:
    return { ...state, isLoginCookie: action.payload };
  default:
    return state;
  }
}

export default reducer;