import React from "react";

import Header from "../../components/header/Header";
import Hero from "../../components/hero/Hero";
import Footer from "../../components/footer/Footer";
import LeagueLogos from "../../components/hero/LeagueLogos";
import HeroImage from "../../components/hero/HeroImage";
import UpToButton from "../../components/upToButton/UpToButtom";

interface BaseLayoutProps {
  children: React.ReactNode;
  leagueLogoActive?: boolean;
  isNavigation?: boolean;
  customHeroClass?: string;
}

function BaseLayout({ children, leagueLogoActive = false, isNavigation = true, customHeroClass  }: BaseLayoutProps) {

  return (
    <>
      <Header isNavigation={isNavigation}>
        <a href="/">
          <div className='logos-container'>
            <img src={`${process.env.PUBLIC_URL}/assets/logos/signet-wasserball-frauen@2x.webp`}
              alt='frauen wasserball liga logo' width={64} height={64} />
            <img src={`${process.env.PUBLIC_URL}/assets/logos/signet-wasserball-maenner@2x.webp`}
              alt='männer wasserball liga logo' width={64} height={64} />
            <img src={`${process.env.PUBLIC_URL}/assets/logos/Wasserball_Bundesliga_schriftzug@2x.webp`}
              alt='schriftzug wasserball liga' width={187} height={64} />
          </div>
        </a>
      </Header>
      <Hero customClass={customHeroClass}>
        <>
          <HeroImage/>
          {leagueLogoActive &&
            <LeagueLogos/>
          }
        </>
      </Hero>
      <main>
        {children}
      </main>
      <Footer>
        <UpToButton/>
      </Footer>
    </>
  );
}

export default BaseLayout;