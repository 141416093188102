import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

import { InitialState as ConfigState } from './configStore';
import { InitialState as NavigationState } from './navigationStore';
import { InitialState as FetchState } from "./fetchActionStore";
import { InitialState as PaginationState } from "./paginationStore";
import { InitialState as TeamsState }  from "./teamsStore";
import { InitialState as NewsRoomState } from "./newsroomStore";
import { InitialState as ScheduleStat } from "./scheduleStore";

import configStore  from "./configStore";
import navigationStore from "./navigationStore";
import fetchDataStore from "./fetchActionStore";
import paginationStore from "./paginationStore";
import teamsStore from "./teamsStore";
import newsroomStore from "./newsroomStore";
import scheduleStore from "./scheduleStore";

export type RootState = {
  config: ConfigState;
  navigation: NavigationState;
  fetchData: FetchState;
  pagination: PaginationState;
  teams: TeamsState;
  newsroom: NewsRoomState;
  schedule: ScheduleStat;
};

const store = configureStore({
  reducer: {
    config: configStore,
    navigation: navigationStore,
    fetchData: fetchDataStore as any,
    pagination: paginationStore,
    teams: teamsStore,
    newsroom: newsroomStore,
    schedule: scheduleStore as any,
  },
  middleware: (getDefaultMiddleware
  ) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }).concat(thunk),
});

export type AppDispatch = typeof store.dispatch;

export default store;