import { useTranslation } from "react-i18next";

interface GameStatisticItemProps {
  title: string;
  home: number;
  guest: number;
  percentHome: number;
  percentGuest: number;
  percent?: boolean;
  customClass?: string;
}

function GameStatisticItem({ title, home, guest, percent, percentHome = 0, percentGuest = 0, customClass = '' }: GameStatisticItemProps) {
  const { t } = useTranslation();
  return (
    <div className="static-item-container">
      <div className="statistic-item team-home">
        {home} {title === 'goals' || title === "errors" || title === 'outnumber' || title === 'goals-after' || title === 'penalty' || title === 'penalty-goals' ? <></> : <span>({percentHome}%)</span>}
        {percentHome ? <div className="bar" style={{ width: percentHome + "%" }}></div> : <><div className="bar" style={{ width: "0.5%" }}></div></>}
      </div>

      <div className="statistic-title">
        {t(`statistic.${title}`)}
      </div>

      <div className="statistic-item team-guest">
        {title === 'goals' || title === "errors" || title === 'outnumber' || title === 'goals-after' || title === 'penalty' || title === 'penalty-goals' ? <></> : <span>({percentGuest}%)</span>} {guest}
        {<div className="bar" style={{ width: percentGuest + "%" }}></div>}
        {percentGuest ? <div className="bar" style={{ width: percentGuest + "%" }}></div> : <><div className="bar" style={{ width: "0.5%" }}></div></>}
      </div>
    </div>
  );
}

export default GameStatisticItem;